import * as Yup from 'yup';
import texts from '../../utils/texts';
import { isObjectEmpty } from '../../utils/helper';

export default {
  handleSubmit: (values, formProps) => {
    const {
      setErrors,
      setSubmitting,
      props: { onSubmit },
    } = formProps;

    const finalValues = {
      sourceAppId: values.sourceApp.value,
      destinationAppId: values.destinationApp.value,
      clearExistingVariables: values.clearExistingVariables,
    };

    const errors = validate(finalValues);
    if (!isObjectEmpty(errors)) {
      setErrors(errors);
      setSubmitting(false);
      return;
    }

    onSubmit(finalValues);
  },
  validationSchema: Yup.object().shape({
    sourceApp: Yup.object()
      .nullable()
      .required(texts.SELECT_VALUE),
    destinationApp: Yup.object()
      .nullable()
      .required(texts.SELECT_VALUE),
    clearExistingVariables: Yup.bool(),
  }),
  mapPropsToValues: () => ({
    sourceApp: null,
    destinationApp: null,
  }),
};

const validate = (values) => {
  const errors = {};
  if (values.sourceAppId === values.destinationAppId) {
    errors.destinationApp = 'Source app and destination app must be different';
  }

  return errors;
};
