import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LoadingSpinner, buttonThemes } from '@sievo/react-common-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Navigation from '../common/Navigation';
import PageContent from '../common/PageContent';
import AppDetailsTabs from './AppDetailsTabs';
import { AppUrls } from '../common/urls';
import appPropTypes from '../Apps/appPropTypes';
import { getSelectedApp, getSelectedCustomerId } from '../utils/routerHelper';
import * as appActions from '../Apps/actions';
import Button from '../common/Buttons/Button';

const AppNameHeader = styled.h4`
  display: flex;
  justify-content: space-between;
`;

const AppDetails = (props) => {
  const { customerApps = [], location, getCustomerApps } = props;
  const customerId = getSelectedCustomerId();
  const selectedApp = getSelectedApp(customerApps);

  useEffect(() => {
    if (customerApps.length === 0) getCustomerApps(customerId);
  }, []);

  if (!selectedApp) return <LoadingSpinner />;

  return (
    <div>
      <PageContent>

        <Navigation
          appsLink={AppUrls.getAppsUrl(customerId)}
          appDetailsLink={location.pathname}
        />
        <AppNameHeader>
          {`${selectedApp.name} Details`}
          <Link to={AppUrls.getEditUrl(customerId, selectedApp.id)}>
            <Button
              title="Edit application details" theme={buttonThemes.whiteWithGrayBorder}
            >
              Edit
              {' '}
              {selectedApp.name}
            </Button>
          </Link>
        </AppNameHeader>
        <AppDetailsTabs {...props} />
      </PageContent>
    </div>
  );
};

AppDetails.propTypes = {
  customerApps: PropTypes.arrayOf(appPropTypes.app),
  getCustomerApps: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  customerApps: state.app.customerApps,
});

const mapDispatchToProps = dispatch => ({
  getCustomerApps: customerId => dispatch(appActions.getCustomerApps(customerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppDetails);
