import React from 'react';
import { buttonThemes, TextCheckbox, IconTooltip } from '@sievo/react-common-components';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Form } from '../../../common/Form';
import LabeledFormInput from '../../../common/Inputs/LabeledFormInput';
import LabeledDropdownInput from '../../../common/Inputs/LabeledDropdownInput';
import Button from '../../../common/Buttons/Button';
import ButtonContainer from '../../../common/ButtonContainer';
import LabeledTextArea from '../../../common/Inputs/LabeledTextArea';
import { categories } from '../Constant';

const VariableForm = (props) => {
  const {
    handleSubmit, onCancel, isSubmitting,
  } = props;

  const categoriesMapped = categories.map(c => ({ value: c.value, label: c.name }));

  return (
    <Form className="variableForm" onSubmit={handleSubmit}>
      <Field name="category" type="text">
        {({ field, form }) => {
          const fieldValue = field.value ? field.value.value : undefined;
          const value = categoriesMapped ? categoriesMapped.find(c => c.value === fieldValue) : '';
          return (
            <LabeledDropdownInput
              value={value}
              onChange={(option) => {
                form.setFieldValue(field.name, option);
              }}
              options={categoriesMapped}
              form={form}
              field={field}
              label="Category"
            />
          );
        }}
      </Field>
      <Field name="name" type="text" component={LabeledFormInput} label="Name" />
      <Field name="description" type="text" component={LabeledFormInput} label="Description" />
      <Field name="value" type="text" component={LabeledTextArea} label="Value" />
      <Field name="availableInSheet">
        {({ field }) => {
          const { name, value, onChange } = field;
          return (
            <>
              <TextCheckbox name={name} text="Available In Sheet" value={value} checked={value} onChange={onChange} />
              <IconTooltip text="Make this variable available to use in the app" place="right" />
            </>
          );
        }}
      </Field>
      <ButtonContainer>
        <Button type="submit" value="Save" disabled={isSubmitting} theme={buttonThemes.lightBlue}>
          Save
        </Button>
        <Button type="button" value="Cancel" disabled={isSubmitting} theme={buttonThemes.white} onClick={onCancel}>
          Cancel
        </Button>
      </ButtonContainer>
    </Form>
  );
};

VariableForm.propTypes = {
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default VariableForm;
