/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import { useCookies } from 'react-cookie';
import { LoadingSpinner, iconButtonThemes, buttonThemes } from '@sievo/react-common-components';
import confirm from '../common/confirm';
import CustomerPropTypes from './customerPropTypes';
import { CustomerUrls, AppUrls } from '../common/urls';
import { HeadingWithButtons, SubHeading } from '../common/Headings';
import { Table, CenterAlignedContent, ColumnHeader } from '../common/Table';
import ActionButton from '../common/Buttons/ActionButton';
import Button from '../common/Buttons/Button';
import TextLink from '../common/TextLink';

const CustomerGrid = ({
  customers, loadingCustomers, deleteCustomerById,
}) => {

  const [cookies, setCookie] = useCookies(['pinnedCustomers']);

  const handleDeleteClick = async (e, cellInfo) => {
    e.stopPropagation();
    const confirmed = await confirm(`Are you sure you want to delete ${cellInfo.original.name}?`, {
      title: `Deleting ${cellInfo.original.name}`,
    });
    if (!confirmed) return;
    deleteCustomerById(cellInfo.original.id);
  };

  const togglePinCustomer = (customerId) => {
    const customerIdString = customerId.toString();
    const pinnedCustomersArray = cookies.pinnedCustomers ? cookies.pinnedCustomers.split('|') : [];
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);

    if (pinnedCustomersArray && pinnedCustomersArray.includes(customerIdString)) {
      const customersToKeep = pinnedCustomersArray.filter(id => id !== customerIdString);
      setCookie('pinnedCustomers', customersToKeep.join('|'), { path: '/', expires: expiryDate });
    } else {
      pinnedCustomersArray.push(customerIdString);
      setCookie('pinnedCustomers', pinnedCustomersArray.join('|'), { path: '/', expires: expiryDate });
    }
  };

  const isCustomerPinned = customerId => cookies.pinnedCustomers
    && cookies.pinnedCustomers.split('|').includes(customerId.toString());

  const columns = [
    {
      Header: () => <ColumnHeader text="Name" />,
      accessor: 'name',
      Cell: cellInfo => (
        <TextLink
          className="customer-name"
          to={AppUrls.getAppsUrl(cellInfo.original.id)}
        >
          {cellInfo.original.name}
        </TextLink>
      ),
      maxWidth: 250,
    },
    {
      Header: () => <ColumnHeader text="Tenant ID" />,
      accessor: 'id',
    },
    {
      Header: () => <ColumnHeader text="Description" />,
      accessor: 'description',
    },
    {
      Header: () => <ColumnHeader text="Backend Central Node" />,
      accessor: 'centralNode',
      maxWidth: 350,
    },
    {
      Header: () => <ColumnHeader text="Frontend Central Node" />,
      accessor: 'frontEndCentralNode',
      maxWidth: 350,
    },
    {
      Header: () => <CenterAlignedContent>Actions</CenterAlignedContent>,
      accessor: 'id',
      maxWidth: 115,
      className: 'actions',
      sortable: false,
      Cell: cellInfo => (
        <CenterAlignedContent>
          <ActionButton
            onClick={() => togglePinCustomer(cellInfo.original.id)}
            icon="sievo-icon-flag"
            tooltip={isCustomerPinned(cellInfo.original.id) ? 'Remove from favorites' : 'Add to favorites'}
            theme={isCustomerPinned(cellInfo.original.id) ? iconButtonThemes.darkBlue : iconButtonThemes.white}
          />
          <Link to={CustomerUrls.getEditUrl(cellInfo.original.id)}>
            <ActionButton icon="sievo-icon-edit" tooltip="Edit customer settings" theme={iconButtonThemes.white} />
          </Link>
          <ActionButton
            onClick={e => handleDeleteClick(e, cellInfo)}
            icon="sievo-icon-trash"
            tooltip="Delete customer"
            theme={iconButtonThemes.red}
          />
        </CenterAlignedContent>
      ),
    },
  ];

  const pinnedCustomers = cookies.pinnedCustomers && customers
    ? customers.filter(customer => isCustomerPinned(customer.id))
    : [];

  const otherCustomers = customers ? customers.filter(customer => !pinnedCustomers.includes(customer)) : [];

  return loadingCustomers ? <LoadingSpinner /> : (
    <div>
      <HeadingWithButtons>
        <h4>Customers</h4>
        <Link to={CustomerUrls.addNew}>
          <Button title="Add new customer" theme={buttonThemes.darkBlue}>
            Create Customer
          </Button>
        </Link>
      </HeadingWithButtons>
      {customers && customers.length === 0 && (<div>No Customer Found</div>) }
      {pinnedCustomers.length > 0 && (
        <div>
          <SubHeading>Favorite Customers</SubHeading>
          <Table
            data={pinnedCustomers}
            columns={columns}
            showPagination={false}
            pageSize={pinnedCustomers.length}
            resizable
            defaultSorted={[{ id: 'name', desc: false }]}
          />
        </div>
      )}
      {otherCustomers && otherCustomers.length > 0 && (
        <div>
          {pinnedCustomers.length > 0 && <SubHeading>Other Customers</SubHeading>}
          <Table
            data={otherCustomers}
            columns={columns}
            showPagination={false}
            pageSize={otherCustomers.length}
            resizable
            defaultSorted={[{ id: 'name', desc: false }]}
          />
        </div>
      )}
    </div>
  );
};

CustomerGrid.propTypes = {
  customers: PropTypes.arrayOf(CustomerPropTypes.customer),
  loadingCustomers: PropTypes.bool,
  deleteCustomerById: PropTypes.func,
};

export default CustomerGrid;
