import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@sievo/react-common-components';
import * as actions from './actions';
import { TabContent } from '../../common/Tabs';
import { getSelectedAppId, getSelectedCustomerId } from '../../utils/routerHelper';
import propTypes from './propTypes';
import SummaryGrid from './SummaryGrid';

const Index = (props) => {
  const { summary, fetchSummary, isLoading } = props;
  const appId = getSelectedAppId();
  const customerId = getSelectedCustomerId();
  const appSummary = summary[appId];

  useEffect(() => {
    if (!appSummary) fetchSummary(customerId, appId);
  }, []);

  if (isLoading) {
    return (
      <TabContent>
        <LoadingSpinner />
      </TabContent>
    );
  }

  return (
    <TabContent>
      {appSummary
        ? <SummaryGrid appId={appId} summary={appSummary} />
        : 'No details found for this app'
      }
    </TabContent>
  );
};

Index.propTypes = {
  fetchSummary: PropTypes.func,
  summary: propTypes.summary,
  isLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
  summary: state.summary.summary,
  isLoading: state.summary.isLoading,
});

const mapDispatchToProps = dispatch => ({
  fetchSummary: (customerId, appId) => dispatch(actions.loadSummary(customerId, appId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
