import styled from 'styled-components';
import { RoundedIconButton, iconButtonThemes, Theme } from '@sievo/react-common-components';

iconButtonThemes.white.border = `1px solid ${Theme.colors.gray}`;

const ActionButton = styled(RoundedIconButton)`
  &.icon-button {
    min-width: 25px !important;
    height: 25px !important;
    margin: 0 5px;
  }

  .button-content__icon span {
    font-size: 15px !important;
  }

  :disabled {
    color: ${Theme.colors.gray} !important;
    background-color: ${Theme.colors.lightGray} !important;
    border-color: ${Theme.colors.gray} !important;
    outline: none !important;
    cursor: not-allowed;
    box-shadow: none !important;
  }
`;

export default ActionButton;
