import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buttonThemes } from '@sievo/react-common-components';
import Button from '../../common/Buttons/Button';
import { reloadApps } from './actions';

const AppReloadButton = ({ triggerReloadInProgess, selectedApps, reloadSelectedApps }) => {
  const buttonDisabled = useMemo(() => !selectedApps?.length || triggerReloadInProgess);

  return (
    <Button
      disabled={buttonDisabled}
      onClick={reloadSelectedApps}
      theme={buttonThemes.darkBlue}
      title="Reload selected apps"
    >
      Reload Apps
    </Button>
  );
};

AppReloadButton.propTypes = {
  triggerReloadInProgess: PropTypes.bool,
  selectedApps: PropTypes.arrayOf(Object),
  reloadSelectedApps: PropTypes.func,
};

const mapStateToProps = state => ({
  triggerReloadInProgess: state.adminApp.appsReloadInProgress,
  selectedApps: state.adminApp.selectedApps,
});

const mapDispatchToProps = dispatch => ({
  reloadSelectedApps: () => dispatch(reloadApps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppReloadButton);
