import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RoundedButton, buttonThemes } from '@sievo/react-common-components';
import variablePropTypes from './variablePropTypes';
import { variableConstants } from '../../utils/constants';

const FilterButton = styled(RoundedButton)`
  font-size: 11px !important;
  min-width: initial;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
`;

const Filters = ({ variables, setDisplayedVariables }) => {
  const [selectedFilter, setSelectedFilter] = useState(variableConstants.consultant);

  useEffect(() => {
    let newVariables = variables;
    if (selectedFilter === variableConstants.consultant) {
      newVariables = variables.filter(variable => variableConstants.consultantCategories.includes(variable.category));
    } else if (selectedFilter === variableConstants.admin) {
      newVariables = variables.filter(variable => !variableConstants.consultantCategories.includes(variable.category));
    }

    setDisplayedVariables(newVariables);

  }, [variables, selectedFilter]);

  return (
    <div>
      <FilterButton
        theme={selectedFilter === variableConstants.consultant ? buttonThemes.darkBlue : buttonThemes.white}
        onClick={() => setSelectedFilter(variableConstants.consultant)}
      >
        Consultant
      </FilterButton>
      <FilterButton
        theme={selectedFilter === variableConstants.admin ? buttonThemes.darkBlue : buttonThemes.white}
        onClick={() => setSelectedFilter(variableConstants.admin)}
      >
        Admin
      </FilterButton>
      <FilterButton
        theme={selectedFilter === variableConstants.all ? buttonThemes.darkBlue : buttonThemes.white}
        onClick={() => setSelectedFilter(variableConstants.all)}
      >
        All
      </FilterButton>
    </div>
  );
};

Filters.propTypes = {
  setDisplayedVariables: PropTypes.func,
  variables: PropTypes.arrayOf(variablePropTypes.variable),
};

export default Filters;
