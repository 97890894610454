import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import variablePropTypes from './variablePropTypes';
import VariableForm from './VariableForm';
import { FormHeader } from '../../common/Form';
import { VariableUrls } from '../../common/urls';
import { editVariable } from './actions';
import { getCurrentPathName, getSelectedAppId } from '../../utils/routerHelper';
import { categories } from './Constant';

const EditVariable = ({
  submit, variables, variableId,
}) => {
  const pathname = getCurrentPathName();
  const appId = getSelectedAppId();
  const variable = variables.find(v => v.id === variableId);
  const category = categories.find(c => c.value === variable.category);
  const initialCategory = variable && { value: category.value, label: category.name };

  const history = useHistory();

  const handleSubmit = (values) => {
    values.id = variable.id;
    submit(appId, values, () => history.push(VariableUrls.getAppVariablesUrl(pathname)));
  };

  const handleCancel = () => {
    history.push(VariableUrls.getAppVariablesUrl(pathname));
  };

  return (
    <React.Fragment>
      <FormHeader>{`Edit variable ${variable.name}`}</FormHeader>
      <VariableForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        appId={appId}
        variables={variables}
        initialValues={{ ...variable, category: initialCategory }}
        variable={variable}
      />
    </React.Fragment>
  );
};

EditVariable.propTypes = {
  variables: PropTypes.arrayOf(variablePropTypes.variable),
  variableId: PropTypes.string,
  submit: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  submit: (appId, values, onSuccess) => dispatch(editVariable(appId, values, onSuccess)),
});

export default connect(
  null,
  mapDispatchToProps,
)(EditVariable);
