export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

const reducer = (state = { messages: {} }, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return { ...state, messages: { ...state.messages, [action.data.type]: action.data.message } };
    case HIDE_MESSAGE:
      return { ...state, messages: {} };
    default:
      return state;
  }
};

export default reducer;
