import * as Yup from 'yup';
import texts from '../../utils/texts';
import { validateApp } from '../../utils/validation';
import { isObjectEmpty } from '../../utils/helper';

export default {
  handleSubmit: (values, formProps) => {
    const {
      setErrors,
      setSubmitting,
      props: {
        onSubmit, selectedCustomer, apps, initialValues,
      },
    } = formProps;

    const finalValues = {
      ...values,
      localeId: values.localeId && values.localeId.value,
      customerId: selectedCustomer.id,
    };

    const errors = validate({ initialValues, finalValues, apps });
    if (!isObjectEmpty(errors)) {
      setErrors(errors);
      setSubmitting(false);
      return;
    }

    setTimeout(() => formProps.setSubmitting(false), 2000);
    onSubmit(finalValues, formProps);
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required(texts.ENTER_VALUE),
    id: Yup.string()
      .required(texts.ENTER_VALUE)
      .uuid(texts.ENTER_VALUE_CORRECT_FORMAT),
    solutionArea: Yup.string().required(texts.ENTER_VALUE),
    localeId: Yup.object()
      .required(texts.SELECT_VALUE),
  }),
  mapPropsToValues: ({ initialValues }) => ({
    // NOTE: initialValues should always be given, in order validation errors to be shown on submit
    name: initialValues ? initialValues.name : '',
    id: initialValues ? initialValues.id : '',
    solutionArea: initialValues ? initialValues.solutionArea : '',
    localeId: initialValues ? initialValues.localeId : '',
    description: initialValues ? initialValues.description : '',
    preloadApp: initialValues ? initialValues.preloadApp : false,
  }),
};

function validate({ initialValues, finalValues, apps }) {
  // Validations which cannot be done with validation schema
  const currentAppId = initialValues && initialValues.id;
  const errors = validateApp(finalValues, apps.filter(a => a.id !== currentAppId));
  return errors;
}
