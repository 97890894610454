import * as actionTypes from './actionTypes';

const updateItemInVariables = (variables, appId, variableToUpdate) => {
  const appVariables = variables[appId];
  const updatedAppVariables = appVariables.map(v => (v.id === variableToUpdate.id ? variableToUpdate : v));

  return {
    ...variables,
    [appId]: updatedAppVariables,
  };
};

const addItemInVariables = (variables, appId, variableToAdd) => {
  const appVariables = variables[appId];
  const updatedAppVariables = [...appVariables, variableToAdd];

  return {
    ...variables,
    [appId]: updatedAppVariables,
  };
};

const reducer = (state = { variables: {} }, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VARIABLES_STARTED:
      return { ...state, isLoading: true };
    case actionTypes.LOAD_VARIABLES_COMPLETED:
      return {
        ...state,
        variables: { ...state.variables, [action.data.appId]: [...action.data.variables] },
        isLoading: false,
      };
    case actionTypes.LOAD_VARIABLES_FAILED:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_VARIABLE_COMPLETED:
      return {
        ...state,
        variables: {
          ...state.variables,
          [action.data.appId]: state.variables[action.data.appId].filter(v => v.id !== action.data.variableId),
        },
      };
    case actionTypes.ADD_VARIABLE_COMPLETED:
      return {
        ...state,
        variables: addItemInVariables(state.variables, action.data.appId, action.data.variable),
      };
    case actionTypes.EDIT_VARIABLE_COMPLETED:
      return {
        ...state,
        variables: updateItemInVariables(state.variables, action.data.appId, action.data.variable),
      };
    case actionTypes.COPY_VARIABLES_COMPLETED:
      return {
        ...state,
        variables: {
          ...state.variables,
          [action.data.destinationAppId]: [...action.data.destinationVariables],
        },
      };
    default:
      return state;
  }
};

export default reducer;
