import * as actionTypes from './actionTypes';

const reducer = (state = { summary: {} }, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SUMMARY_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOAD_SUMMARY_COMPLETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          [action.data.appId]: action.data.summary,
        },
        isLoading: false,
      };
    case actionTypes.LOAD_SUMMARY_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
