import React from 'react';
import { buttonThemes, TextCheckbox } from '@sievo/react-common-components';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { Form, FormContainer } from '../../common/Form';
import LabeledFormInput from '../../common/Inputs/LabeledFormInput';
import LabeledAutosuggest from '../../common/Inputs/LabeledAutosuggest';
import LabeledDropdownInput from '../../common/Inputs/LabeledDropdownInput';
import Button from '../../common/Buttons/Button';
import ButtonContainer from '../../common/ButtonContainer';
import appPropType from '../appPropTypes';
import localePropTypes from '../../Admin/Locales/localePropTypes';

const AppForm = (props) => {
  const {
    handleSubmit,
    onCancel,
    isSubmitting,
    apps,
    locales,
    createNew,
    isProductionApp,
  } = props;

  const localesMapped = (locales || []).map(locale => ({
    value: locale.id.toString(),
    label: locale.name,
  }));

  const user = useSelector(state => state.user.user);

  const solutionAreas = [...new Set((apps || []).map(c => c.solutionArea))].sort();

  return (
    <FormContainer>
      <Form className="customerForm" onSubmit={handleSubmit}>
        <Field name="name" type="text" component={LabeledFormInput} label="Name" />
        <Field name="id" type="text" component={LabeledFormInput} label="App Id" disabled={!createNew} />
        <Field name="description" type="text" component={LabeledFormInput} label="Description" />
        <Field
          name="solutionArea"
          type="text"
          component={LabeledAutosuggest}
          options={solutionAreas}
          label="Solution Area"
        />
        <Field name="localeId" type="text">
          {({ field, form }) => (
            <LabeledDropdownInput
              value={field.value}
              onChange={(option) => {
                form.setFieldValue(field.name, option);
              }}
              options={localesMapped}
              form={form}
              field={field}
              label="Locale"
            />
          )}
        </Field>
        { (!createNew && user?.isAdmin && isProductionApp)
          && (
            <Field name="preloadApp">
              {({ field }) => (
                <TextCheckbox
                  text="Preload app"
                  name={field.name}
                  id={field.name}
                  value={field.value}
                  checked={field.value}
                  {...field}
                />
              )}
            </Field>
          )
        }
        <ButtonContainer>
          <Button type="submit" value="Save" disabled={isSubmitting} theme={buttonThemes.lightBlue}>
            Save
          </Button>
          <Button type="button" value="Cancel" disabled={isSubmitting} theme={buttonThemes.white} onClick={onCancel}>
            Cancel
          </Button>
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
};

AppForm.propTypes = {
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  initialValues: PropTypes.shape({ name: PropTypes.string }),
  values: PropTypes.shape({ name: PropTypes.string }),
  apps: PropTypes.arrayOf(appPropType.app),
  locales: PropTypes.arrayOf(localePropTypes.locale),
  createNew: PropTypes.bool,
  isProductionApp: PropTypes.bool,
};

export default AppForm;
