import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import appPropTypes from '../../Apps/appPropTypes';
import customerPropTypes from '../../Customers/customerPropTypes';
import { appsSelected } from './actions';

const FiltersContainer = styled.div`
  display: flex;

  > div:not(:last-child) {
    margin-right: 15px;
  }
`;

const FilterContainer = styled.div`
  width: 50%;
`;

export const AppFiltersComponent = ({
  apps = [], setFilteredApps, customers = [], isUpdating, setSelectedApps,
}) => {
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const customerOptions = useMemo(() => customers.map(customer => ({ label: customer.name, value: customer.id }))
    .sort((a, b) => a.label.localeCompare(b.label)), [customers]);

  const solutionAreas = useMemo(() => [...new Set(apps.map(app => app.solutionArea))].sort(), [apps]);
  const solutionAreaOptions = solutionAreas.map(area => ({ label: area, value: area }));

  useEffect(() => {
    const filteredByCustomer = apps.filter(app => selectedCustomers && selectedCustomers.length > 0
      ? selectedCustomers.some(customer => customer.value === app.customerId)
      : true);

    const filteredByArea = filteredByCustomer.filter(app => selectedAreas && selectedAreas.length > 0
      ? selectedAreas.some(area => area.value === app.solutionArea)
      : true);

    setFilteredApps(filteredByArea);

    setSelectedApps([]);
  }, [selectedCustomers, selectedAreas]);

  return (
    <FiltersContainer>
      <FilterContainer>
        Customer
        <Select
          options={customerOptions}
          isMulti
          isSearchable
          value={selectedCustomers}
          onChange={options => setSelectedCustomers(options)}
          isDisabled={isUpdating}
        />
      </FilterContainer>
      <FilterContainer>
        Solution Area
        <Select
          options={solutionAreaOptions}
          isMulti
          isSearchable
          value={selectedAreas}
          onChange={options => setSelectedAreas(options)}
          isDisabled={isUpdating}
        />
      </FilterContainer>
    </FiltersContainer>
  );
};

AppFiltersComponent.propTypes = {
  apps: PropTypes.arrayOf(appPropTypes.app),
  setFilteredApps: PropTypes.func,
  customers: PropTypes.arrayOf(customerPropTypes.customer),
  isUpdating: PropTypes.bool,
  setSelectedApps: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  setSelectedApps: selectedApps => dispatch(appsSelected(selectedApps)),
});

export default connect(null, mapDispatchToProps)(AppFiltersComponent);
