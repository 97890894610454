import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styled from 'styled-components';
import { RoundedDropdown } from '@sievo/react-common-components';
import withLabel from '../withLabel';
import inputDropdownTheme from './dropdownTheme';
import withValidation from '../withValidation';
import commonPropTypes from '../commonPropTypes';

const Error = styled.div`
  margin-top: 5px;
  color: red;
`;

const DropdownInput = (props) => {
  const { touched, error } = props;
  return (
    <div>
      <RoundedDropdown theme={inputDropdownTheme.theme} searchable={false} className="form-dropdown" {...props} />
      {touched && error && <Error>{error}</Error>}
    </div>
  );
};

DropdownInput.propTypes = {
  options: PropTypes.arrayOf(commonPropTypes.option),
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.shape(commonPropTypes.error),
};

const enhance = compose(
  withLabel,
  withValidation,
);

export default enhance(DropdownInput);
