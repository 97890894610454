import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useHistory } from 'react-router-dom';
import { StyledTabs } from '../common/Tabs';
import Summary from './Summary/Index';
import Tasks from './Tasks/Index';
import Variables from './Variables/Index';
import { getCurrentPathName, getQueryParams } from '../utils/routerHelper';

const renderTab = (history, pathname, k) => {
  history.push(`${pathname}?key=${k}`);
};

const AppDetailsTabs = (props) => {
  const { key } = getQueryParams();
  const pathname = getCurrentPathName();
  const history = useHistory();

  return (
    <StyledTabs
      id="app-detail-tabs"
      activeKey={key}
      mountOnEnter
      unmountOnExit
      onSelect={(k) => {
        renderTab(history, pathname, k);
      }}
    >
      <Tab eventKey="summary" title="Summary">
        <Summary {...props} />
      </Tab>
      <Tab eventKey="tasks" title="Tasks">
        <Tasks {...props} />
      </Tab>
      <Tab eventKey="variables" title="Variables">
        <Variables {...props} />
      </Tab>
    </StyledTabs>
  );
};

AppDetailsTabs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: ReactRouterPropTypes.match,
};

export default AppDetailsTabs;
