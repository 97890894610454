import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@sievo/react-common-components';
import * as actions from './actions';
import { TabContent } from '../../common/Tabs';
import AddNew from './Add';
import Edit from './Edit';
import DefaultTabView from './DefaultTabView';
import variablePropTypes from './variablePropTypes';
import { getQueryParams, getSelectedAppId } from '../../utils/routerHelper';

export const Index = (props) => {
  const {
    isLoading, variables, loadVariables,
  } = props;
  const appId = getSelectedAppId();

  useEffect(() => {
    loadVariables(appId);
  }, []);

  const { action, variable } = getQueryParams();

  if (!variables) return null;

  const renderViewByAction = () => {
    switch (action) {
      case 'add':
        return <AddNew {...props} />;
      case 'edit': {
        return <Edit {...props} variableId={variable} />;
      }
      default:
        return <DefaultTabView {...props} />;
    }
  };

  return <TabContent>{isLoading ? <LoadingSpinner /> : renderViewByAction()}</TabContent>;
};

Index.propTypes = {
  isLoading: PropTypes.bool,
  variables: PropTypes.arrayOf(variablePropTypes.variable),
  loadVariables: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  variables: state.variable.variables[ownProps.match.params.appId],
  isLoading: state.variable.isLoading,
});

export const mapDispatchToProps = dispatch => ({
  loadVariables: appId => dispatch(actions.loadVariables(appId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
