// eslint-disable-next-line import/prefer-default-export
export const categories = [
  { value: 'UnClassified', name: 'UnClassified' },
  { value: 'QlikVariable', name: 'QlikVariable' },
  { value: 'SievoEnvironment', name: 'SievoEnvironment' },
  { value: 'SievoInLoadScript', name: 'SievoInLoadScript' },
  { value: 'SievoInSheet', name: 'SievoInSheet' },
  { value: 'ProjectSpecific', name: 'ProjectSpecific' },
  { value: 'SievoQdf', name: 'SievoQdf' },
];
