/* eslint-disable react/destructuring-assignment */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TextCheckbox, Theme } from '@sievo/react-common-components';
import { AppUrls } from '../../common/urls';
import { Table, CenterAlignedContent, ColumnHeader } from '../../common/Table';
import appPropTypes from '../../Apps/appPropTypes';
import TextLink from '../../common/TextLink';
import { formatDateString } from '../../utils/dateConverter';
import { generalConstants } from '../../utils/constants';
import { appsSelected } from './actions';

const RemoveCheckboxMargins = styled.div`
  .text-checkbox__content .text-checkbox__item {
    margin-right: 0;
  }
`;

const VariableValueCell = styled.span`
  color: ${props => props.notFound ? Theme.colors.gray : Theme.colors.black};
`;

export const Grid = ({
  apps = [], selectedApps = [], selectedVariable, appVariables, setSelectedApps,
}) => {
  const selectedAppVariables = useMemo(() => {
    if (!appVariables) return;

    return appVariables[selectedVariable.value];
  }, [appVariables, selectedVariable]);

  const appsWithVariables = useMemo(() => {
    if (!selectedAppVariables) return apps;

    return apps.map((app) => {
      const appVariable = selectedAppVariables.find(v => v.appId === app.id);
      let variableValue = 'Variable not found';
      if (appVariable) { variableValue = appVariable.value; }

      return {
        ...app,
        variableValue,
      };
    });
  }, [apps, selectedAppVariables]);

  const allSelected = useMemo(() => {
    if (!apps.length || !selectedApps.length) return false;

    return selectedApps.length === apps.length;
  }, [apps, selectedApps]);

  const onChangeSelectAll = () => {
    if (allSelected) setSelectedApps([]);
    else setSelectedApps(apps);
  };

  const isAppSelected = id => selectedApps.some(app => app.id === id);

  const onChangeAppSelected = (id) => {
    if (isAppSelected(id)) {
      setSelectedApps(selectedApps.filter(app => app.id !== id));
    } else {
      const newSelected = [...selectedApps, apps.find(app => app.id === id)];
      setSelectedApps(newSelected);
    }
  };

  const columns = [
    {
      Header: () => (
        <CenterAlignedContent>
          <RemoveCheckboxMargins>
            <TextCheckbox checked={allSelected} onChange={() => onChangeSelectAll()} />
          </RemoveCheckboxMargins>
        </CenterAlignedContent>
      ),
      accessor: 'selected',
      width: 50,
      sortable: false,
      Cell: cellInfo => (
        <CenterAlignedContent>
          <RemoveCheckboxMargins>
            <TextCheckbox
              checked={isAppSelected(cellInfo.original.id)}
              onChange={() => onChangeAppSelected(cellInfo.original.id)}
            />
          </RemoveCheckboxMargins>
        </CenterAlignedContent>
      ),
    },
    {
      Header: () => <ColumnHeader text="Name" />,
      accessor: 'name',
      Cell: cellInfo => (
        <TextLink
          to={AppUrls.getAppDetailsSummaryUrl(cellInfo.original.customerId, cellInfo.original.id)}
        >
          {cellInfo.original.name}
        </TextLink>
      ),
    },
    {
      Header: () => <ColumnHeader text="Id" />,
      accessor: 'id',
    },
    {
      Header: () => <ColumnHeader text="Last Reload" />,
      accessor: 'lastReloadTime',
      Cell: cellInfo => cellInfo.original.lastReloadTime !== generalConstants.emptyTime
        ? formatDateString(cellInfo.original.lastReloadTime)
        : '',
    },
    {
      Header: () => <ColumnHeader text="Pushed to Production" />,
      accessor: 'pushToProductionTime',
      Cell: cellInfo => !cellInfo.original.isProductionApp
        && cellInfo.original.pushToProductionTime !== generalConstants.emptyTime
        ? formatDateString(cellInfo.original.pushToProductionTime)
        : '',
    },
    {
      Header: () => <ColumnHeader text={`${selectedVariable?.label || 'Variable'} value`} />,
      accessor: 'variableValue',
      Cell: cellInfo => (
        <VariableValueCell
          notFound={cellInfo.original.variableValue === 'Variable not found'}
        >
          {selectedVariable ? cellInfo.original.variableValue : ''}
        </VariableValueCell>
      ),
    },
  ];

  return appsWithVariables.length > 0 ? (
    <Table
      data={appsWithVariables}
      columns={columns}
      showPagination={false}
      pageSize={apps.length}
      resizable
      defaultSorted={[{ id: 'name', desc: false }]}
    />
  ) : <p>No apps found</p>;
};

Grid.propTypes = {
  apps: PropTypes.arrayOf(appPropTypes.app),
  selectedApps: PropTypes.arrayOf(appPropTypes.app),
  appVariables: PropTypes.instanceOf(Object),
  selectedVariable: PropTypes.instanceOf(Object),
  setSelectedApps: PropTypes.func,
};

const mapStateToProps = state => ({
  selectedVariable: state.adminApp.selectedVariable,
  appVariables: state.adminApp.appVariables,
  selectedApps: state.adminApp.selectedApps,
});

const mapDispatchToProps = dispatch => ({
  setSelectedApps: selectedApps => dispatch(appsSelected(selectedApps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Grid);
