import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const NotificationSetting = ({
  email, appId, type, notifyHandler,
}) => {
  const [cookies, setCookies] = useCookies([type]);
  useEffect(() => {
    notifyHandler(cookies[type] && cookies[type][appId]);
  }, [cookies]);

  const saveNotification = (value) => {
    const notifications = { ...cookies[type], [appId]: value };
    notifyHandler(value);
    setCookies(type, notifications, { path: '/' });
  };

  const notificationLabelText = () => (
    <>
      <strong>
        Send notification when completed
      </strong>
      <br />
      <span style={{ fontSize: '0.875rem' }}>
        Notification will be sent to
        {' '}
        <span className="text-primary">{email}</span>
      </span>
    </>
  );

  return (
    <>
      <Form.Group controlId="notificationCheckBox">
        <Form.Check
          type="checkbox" checked={(cookies[type] && cookies[type][appId]) || false}
          onChange={event => saveNotification(event.target.checked ? email : '')} label={notificationLabelText()}
        />
      </Form.Group>
    </>
  );
};

NotificationSetting.propTypes = {
  email: PropTypes.string,
  appId: PropTypes.string,
  type: PropTypes.oneOf(['pushToProduction', 'reload']).isRequired,
  notifyHandler: PropTypes.func,
};

export default NotificationSetting;
