import React from 'react';
import PropTypes from 'prop-types';
import { buttonThemes } from '@sievo/react-common-components';
import { Field } from 'formik';
import { Form, FormContainer } from '../../common/Form';
import LabeledFormInput from '../../common/Inputs/LabeledFormInput';
import LabeledAutosuggest from '../../common/Inputs/LabeledAutosuggest';
import Button from '../../common/Buttons/Button';
import ButtonContainer from '../../common/ButtonContainer';
import customerPropTypes from '../customerPropTypes';

const CustomerForm = (props) => {
  const {
    handleSubmit, onCancel, isSubmitting, setFieldValue, allCustomers, createNew,
  } = props;

  const setDefaultQlikSettings = (centralNode) => {
    const defaults = allCustomers.find(c => c.centralNode === centralNode);
    if (defaults == null) return;
    setFieldValue('centralNode', defaults.centralNode);
    setFieldValue('frontEndCentralNode', defaults.frontEndCentralNode);
  };

  const centralNodes = [...new Set((allCustomers || []).map(c => c.centralNode))].sort();

  return (
    <FormContainer>
      <Form border className="customerForm" onSubmit={handleSubmit}>
        <Field className="form-control" name="name" component={LabeledFormInput} label="Name" />
        <Field name="id" type="text" component={LabeledFormInput} label="Customer Id (TenantId)" disabled={!createNew} />
        <Field name="description" type="text" component={LabeledFormInput} label="Description" />
        <Field
          name="centralNode"
          options={centralNodes}
          onBlur={setDefaultQlikSettings} type="text" component={LabeledAutosuggest} label="Back-end central node"
        />
        <Field name="frontEndCentralNode" type="text" component={LabeledFormInput} label="Front-end central node" />
        <ButtonContainer>
          <Button type="submit" value="Save" disabled={isSubmitting} theme={buttonThemes.lightBlue}>
            Save
          </Button>
          <Button type="button" value="Cancel" disabled={isSubmitting} theme={buttonThemes.white} onClick={onCancel}>
            Cancel
          </Button>
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
};

CustomerForm.propTypes = {
  values: PropTypes.shape({ name: PropTypes.string }),
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
  allCustomers: PropTypes.arrayOf(customerPropTypes.customer),
  createNew: PropTypes.bool,
};

export default CustomerForm;
