import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RoundedDropdown } from '@sievo/react-common-components';
import inputDropdownTheme from './dropdownTheme';

const Container = styled.div`
  width: ${props => props.width};
`;

const Dropdown = (props) => {
  const { width } = props;
  return (
    <Container width={width}>
      <RoundedDropdown theme={inputDropdownTheme.theme} {...props} />
    </Container>
  );
};

Dropdown.propTypes = {
  width: PropTypes.string,
};

export default Dropdown;
