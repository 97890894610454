import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import formHandlers from './formHandlers';
import Form from './Form';
import { categories } from '../Constant';
import variablePropType from '../variablePropTypes';

const withFormFieldsOptions = (Children) => {
  const wrapped = (props) => {
    wrapped.propTypes = {
      categories: PropTypes.arrayOf(variablePropType.category),
    };

    return categories ? <Children categories={categories} {...props} /> : null;
  };

  return wrapped;
};

export default withFormFieldsOptions(withFormik(formHandlers)(Form));
