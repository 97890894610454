import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from '../../common/Inputs/Dropdown';
import { getDistinctVariableNames, getVariablesByName, variableSelected } from './actions';

export const VariableDropdown = ({
  variables, selectedVariable, fetchVariables, isUpdating, fetchAppVariables, setSelectedVariable,
}) => {
  useEffect(() => {
    if (!variables) {
      fetchVariables();
    }
    if (!selectedVariable) {
      setSelectedVariable({ label: 'Select variable', value: -1 });
    }
  }, []);

  const options = useMemo(() => {
    if (!variables) return [];

    return Object.keys(variables).map(key => ({
      label: variables[key],
      value: key,
    }));
  }, [variables]);

  const onVariableChange = (option) => {
    setSelectedVariable(option);
    fetchAppVariables(option.label, option.value);
  };

  return (
    <>
      <span>Variable:</span>
      <Dropdown
        value={selectedVariable}
        options={options}
        onChange={onVariableChange}
        searchable
        width="300px"
        disabled={isUpdating}
      />
    </>
  );
};

VariableDropdown.propTypes = {
  variables: PropTypes.instanceOf(Object),
  selectedVariable: PropTypes.instanceOf(Object),
  isUpdating: PropTypes.bool,
  fetchVariables: PropTypes.func,
  fetchAppVariables: PropTypes.func,
  setSelectedVariable: PropTypes.func,
};

const mapStateToProps = state => ({
  variables: state.adminApp.variables,
  selectedVariable: state.adminApp.selectedVariable,
});

const mapDispatchToProps = dispatch => ({
  fetchVariables: () => dispatch(getDistinctVariableNames()),
  fetchAppVariables: (variableName, variableIndex) => dispatch(getVariablesByName(variableName, variableIndex)),
  setSelectedVariable: variable => dispatch(variableSelected(variable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VariableDropdown);
