const texts = {
  ENTER_VALUE: 'Please enter a value',
  SELECT_VALUE: 'Please select a value',
  ENTER_VALUE_CORRECT_FORMAT: 'Please enter a value in the correct format',
  ENTER_NUMBER: 'Please enter a number',
  VALUE_MUST_BE_UNIQUE: 'Please enter a unique value',
  MAX_LENGTH_EXCEEDED: length => `Maximum length is  ${length} characters`,
};

export default texts;
