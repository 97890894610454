import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RoundedIconButton } from '@sievo/react-common-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
  color: ${props => props.theme.sievoLib.colors.white};
  background: ${props => props.theme.sievoLib.colors.darkBlue};
  padding: 12px 20px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: baseline;

  h3 {
    margin: 0;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    padding-right: 5px;    

  h4 {
    margin: 0 0 0 10px;
    font-weight: 400;
    font-size: 18px;
  }
`;

export const HeaderButton = styled(RoundedIconButton)`
  &.header-button {
    height: 30px !important;
    min-width: 30px !important;
  }
`;

export const HeaderLink = styled(Link)`
  color: ${props => props.theme.sievoLib.colors.white};
  text-decoration: none;
  font-weight: 600;
  line-height: 2;

  &:hover {
    color: ${props => props.theme.sievoLib.colors.lightGray};
    text-decoration: none;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 20px;
    color: ${props => props.theme.sievoLib.colors.white} !important;
  }
`;
