import appService from './appService';
import * as ActionTypes from './reducer';
import withErrorHandling from '../common/withErrorHandling';

const appAddedSuccesfully = app => ({
  type: ActionTypes.APP_ADDED,
  data: { app },
});

const appUpdatedSuccessfully = app => ({
  type: ActionTypes.APP_UPDATED,
  data: { app },
});

const appDeletedSuccessfully = id => ({
  type: ActionTypes.CUSTOMER_APP_DELETED,
  data: { id },
});

export const customerAppsDeletedSuccessfully = customerId => ({
  type: ActionTypes.CUSTOMER_APPS_DELETED,
  data: { customerId },
});

export const appLoadingStarted = () => ({ type: ActionTypes.LOADING_APPS });

export const appsLoaded = apps => ({ type: ActionTypes.APPS_LOADED, data: apps });

export const customerAppsLoaded = apps => ({ type: ActionTypes.CUSTOMER_APPS_LOADED, data: apps });

export const getApps = () => withErrorHandling(async (dispatch) => {
  dispatch(appLoadingStarted());
  dispatch(loadAppsSilently());
});

export const getCustomerApps = customerId => withErrorHandling(async (dispatch) => {
  dispatch(appLoadingStarted());
  const apps = await appService.getCustomerApps(customerId);
  dispatch(customerAppsLoaded(apps));
});

export const loadAppsSilently = () => async (dispatch) => {
  const apps = await appService.getAll();
  dispatch(appsLoaded(apps));
};

export const appAdded = (app, onAdded) => withErrorHandling(async (dispatch) => {
  const addedApp = await appService.add(app);
  dispatch(appAddedSuccesfully(addedApp));
  onAdded();
});

export const updateApp = (app, onUpdated) => withErrorHandling(async (dispatch) => {
  await appService.update(app);
  dispatch(appUpdatedSuccessfully(app));
  onUpdated();
});

export const deleteAppById = (customerId, appId) => withErrorHandling(async (dispatch) => {
  await appService.delete(customerId, appId);
  dispatch(appDeletedSuccessfully(appId));
});
