import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const getSelectedApp = (apps) => {
  if (!apps) return null;

  const appId = getSelectedAppId();
  if (!appId) return null;

  return apps.find(a => a.id === appId);
};

export const getSelectedCustomer = (customers) => {
  if (!customers) return null;

  const customerId = getSelectedCustomerId();
  if (!customerId) return null;

  return customers.find(a => a.id === customerId);
};

export const getSelectedAppId = () => {
  const { appId } = useParams();

  return appId;
};

export const getSelectedCustomerId = () => {
  const { customerId } = useParams();

  return customerId;
};

export const getCurrentPathName = () => {
  const location = useLocation();

  return location.pathname;
};

export const getQueryParams = () => {
  const { search } = useLocation();

  return queryString.parse(search);
};
