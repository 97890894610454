/* eslint-disable import/prefer-default-export */
import localeService from './localeService';
import * as ActionTypes from './reducer';
import withErrorHandling from '../../common/withErrorHandling';

const localesReceived = data => ({
  type: ActionTypes.LOCALES_RECEIVED,
  data,
});

export const getLocales = () => withErrorHandling(async (dispatch) => {
  const locales = await localeService.getAll();
  dispatch(localesReceived(locales));
});
