import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const withLabel = (Children) => {
  const wrapped = (props) => {
    const { label } = props;
    return (
      <Container>
        <span>{label}</span>

        <Children {...props} />
      </Container>
    );
  };

  wrapped.propTypes = {
    label: PropTypes.string,
  };

  return wrapped;
};

withLabel.propTypes = {
  Children: PropTypes.element,
};

export default withLabel;
