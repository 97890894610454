import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { AppUrls } from '../common/urls';
import AppForm from './AppForm';
import { SmallPageContent } from '../common/PageContent';
import customerPropTypes from '../Customers/customerPropTypes';
import { FormHeader } from '../common/Form';
import { getSelectedCustomer } from '../utils/routerHelper';

const AddNew = (props) => {
  const { addApp, customers } = props;
  const selectedCustomer = getSelectedCustomer(customers);
  const history = useHistory();
  if (!selectedCustomer) return null;

  const handleSubmit = (values, formikBag) => {
    addApp(values, () => history.push(AppUrls.getAppsUrl(selectedCustomer.id)));
    setTimeout(() => formikBag.setSubmitting(false), 2000);
  };
  const handleCancel = () => history.push(AppUrls.getAppsUrl(selectedCustomer.id));

  return (
    <SmallPageContent>
      <FormHeader>{`Add app for ${selectedCustomer.name}`}</FormHeader>
      <AppForm onSubmit={handleSubmit} onCancel={handleCancel} {...props} selectedCustomer={selectedCustomer} createNew />
    </SmallPageContent>
  );
};

AddNew.propTypes = {
  addApp: PropTypes.func,
  customers: PropTypes.arrayOf(customerPropTypes.customer),
};

export default AddNew;
