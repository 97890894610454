import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Theme } from '@sievo/react-common-components';

const StyledIcon = styled.i`
  color: ${props => Theme.colors[props.color]};
  font-weight: bold !important;
  font-size: 20px;
`;

const TableIcon = ({ color, icon, title }) => <StyledIcon color={color} title={title} className={`sievo-icon-${icon}`} />;

TableIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
};

export default TableIcon;
