import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import CustomerPropTypes from './customerPropTypes';
import CustomerForm from './CustomerForm/index';
import { SmallPageContent } from '../common/PageContent';
import { FormHeader } from '../common/Form';
import { getSelectedCustomer } from '../utils/routerHelper';

const Edit = ({ updateCustomer, customers }) => {
  const history = useHistory();
  if (!customers) return null;
  const selectedCustomer = getSelectedCustomer(customers);

  const handleSubmit = values => updateCustomer(values, () => history.goBack());
  const handleCancel = () => history.goBack();

  return (
    !!selectedCustomer && (
      <SmallPageContent>
        <FormHeader>Edit Customer</FormHeader>
        <CustomerForm
          initialValues={selectedCustomer}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          allCustomers={customers}
        />
      </SmallPageContent>
    )
  );
};

Edit.propTypes = {
  updateCustomer: PropTypes.func,
  customers: PropTypes.arrayOf(CustomerPropTypes.customer),
};

export default Edit;
