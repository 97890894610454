import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Theme } from '@sievo/react-common-components';
import { Table, ColumnHeader } from '../../common/Table';
import localePropTypes from './localePropTypes';

const Container = styled.div`
  flex: 1;
`;

const LocaleGrid = ({ locales, selectedLocaleId, setSelectedLocaleId }) => {
  const columns = [
    {
      Header: () => <ColumnHeader text="Name" />,
      accessor: 'name',
    },
    {
      accessor: 'id',
      show: false,
    },
  ];

  return (
    <Container>
      <h4>Locales</h4>
      <Table
        data={locales}
        columns={columns}
        pageSize={locales ? locales.length : 0}
        showPagination={false}
        resizable
        getTrProps={(_, rowInfo) => {
          if (!rowInfo || !rowInfo.row) return {};
          const rowSelected = rowInfo.original.id === selectedLocaleId;
          return {
            onClick: () => setSelectedLocaleId(rowInfo.original.id),
            style: {
              background: rowSelected ? Theme.colors.selected : 'transparent',
            },
          };
        }}
      />
    </Container>
  );
};

LocaleGrid.propTypes = {
  locales: PropTypes.arrayOf(localePropTypes.locale),
  setSelectedLocaleId: PropTypes.func,
  selectedLocaleId: PropTypes.string,
};

export default LocaleGrid;
