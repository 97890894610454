import React from 'react';
import { connect } from 'react-redux';
import commonPropTypes from './common/commonPropTypes';
import { MESSAGE_TYPE } from './common/withErrorHandling';

const MessageBar = ({ messages }) => {
  const getClassNames = React.useCallback((type) => {
    let classNames = 'alert ';
    switch (type) {
      case MESSAGE_TYPE.SUCCESS:
        classNames += 'alert-success';
        break;
      case MESSAGE_TYPE.ERROR:
        classNames += 'alert-danger';
        break;
      case MESSAGE_TYPE.INFO:
        classNames += 'alert-info';
        break;
      case MESSAGE_TYPE.WARNING:
        classNames += 'alert-warning';
        break;
      default: classNames += '';
    }

    return classNames;
  }, []);

  return messages && (
    <React.Fragment>
      {Object.entries(messages).map(([key, value]) => (
        <div key={key} className={getClassNames(key)} role="alert">
          {value}
        </div>
      ))
      }
    </React.Fragment>
  );
};

MessageBar.propTypes = {
  messages: commonPropTypes.message,
};

const mapStateToProps = state => ({
  messages: state.common.messages,
});

export default connect(mapStateToProps)(MessageBar);
