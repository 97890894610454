import * as appActions from '../Apps/actions';
import * as customerActions from '../Customers/actions';
import * as localeActions from '../Admin/Locales/actions';
import * as taskActions from '../AppDetails/Tasks/actions';

export function mapStateToProps(state) {
  return {
    customers: state.customer.customers,
    loadingCustomers: state.customer.loading,
    customerAmount: state.customer.customers == null ? 1 : state.customer.customers.length,
    locales: state.locale.locales,
    apps: state.app.apps,
    loadingApps: state.app.loadingApps,
    tasks: state.tasks.tasks,
    reloadingApps: state.tasks.reloadingApps,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    deleteCustomerById: customerId => dispatch(customerActions.deleteCustomerById(customerId)),
    getCustomers: () => dispatch(customerActions.getCustomers()),
    addCustomer: (customer, onAdded) => dispatch(customerActions.customerAdded(customer, onAdded)),
    updateCustomer: (customer, onUpdated) => dispatch(customerActions.updateCustomer(customer, onUpdated)),
    appLoadingStarted: () => dispatch(appActions.appLoadingStarted()),
    appsLoaded: apps => dispatch(appActions.appsLoaded(apps)),
    getApps: () => dispatch(appActions.getApps()),
    addApp: (app, onAdded) => dispatch(appActions.appAdded(app, onAdded)),
    updateApp: (app, onUpdated) => dispatch(appActions.updateApp(app, onUpdated)),
    deleteAppById: (customerId, appId) => dispatch(appActions.deleteAppById(customerId, appId)),
    pushAppToProduction: (customerId, appId, reloadBeforePush, notificationEmail) => dispatch(taskActions.pushToProduction(customerId, appId, reloadBeforePush, notificationEmail)),
    reloadApp: (appId, notificationEmail) => dispatch(taskActions.reload(appId, notificationEmail)),
    appReloaded: appId => dispatch(taskActions.appReloaded(appId)),
    getLocales: () => dispatch(localeActions.getLocales()),
  };
}
