/* eslint-disable import/prefer-default-export */
import * as actionTypes from './actionTypes';
import service from '../../Apps/appService';
import withErrorHandling from '../../common/withErrorHandling';

const loadSummaryStarted = () => ({
  type: actionTypes.LOAD_SUMMARY_STARTED,
});

const loadSummaryCompleted = ({ appId, summary }) => ({
  type: actionTypes.LOAD_SUMMARY_COMPLETED,
  data: { appId, summary },
});

const loadSummaryFailed = () => ({
  type: actionTypes.LOAD_SUMMARY_FAILED,
});

export const loadSummary = (customerId, appId) => withErrorHandling(async (dispatch) => {
  try {
    dispatch(loadSummaryStarted());
    const summary = await service.getAppDetails(customerId, appId);
    dispatch(loadSummaryCompleted({ appId, summary }));
  } catch (e) {
    dispatch(loadSummaryFailed());
    throw e;
  }
});
