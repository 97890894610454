import React from 'react';
import CustomerGrid from './CustomerGrid';
import PageContent from '../common/PageContent';

const Customers = props => (
  <PageContent>
    <CustomerGrid {...props} />
  </PageContent>
);

export default Customers;
