import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { buttonThemes } from '@sievo/react-common-components';
import PropTypes from 'prop-types';
import Button from '../../common/Buttons/Button';
import ButtonContainer from '../../common/ButtonContainer';

import NotificationSetting from './NotificationSetting';

const ReloadAppModal = ({
  showModal,
  closeModal,
  confirmReload,
  notificationEmail,
  appId,
}) => {
  const [notify, setNotify] = useState('');

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Reload Qlik Sense app</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to trigger a reload?</p>
        <Form>
          <NotificationSetting email={notificationEmail} appId={appId} type="reload" notifyHandler={value => setNotify(value)} />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonContainer>
          <Button
            type="button"
            onClick={closeModal}
            theme={buttonThemes.white}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => confirmReload(notify)}
            theme={buttonThemes.darkBlue}
          >
            Confirm
          </Button>
        </ButtonContainer>
      </Modal.Footer>
    </Modal>
  );
};

ReloadAppModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  confirmReload: PropTypes.func,
  notificationEmail: PropTypes.string,
  appId: PropTypes.string,
};

export default ReloadAppModal;
