import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TabContent } from '../../common/Tabs';
import LocaleGrid from './LocaleGrid';
import LocaleItemsGrid from './LocaleItemsGrid';
import localePropTypes from './localePropTypes';

const Content = styled.div`
  display: flex;
`;

const Locales = ({ locales }) => {
  const [selectedLocaleId, setSelectedLocaleId] = useState(null);

  return (
    <TabContent>
      <Content>
        <LocaleGrid locales={locales} selectedLocaleId={selectedLocaleId} setSelectedLocaleId={setSelectedLocaleId} />
        <LocaleItemsGrid selectedLocaleId={selectedLocaleId} />
      </Content>
    </TabContent>
  );
};

Locales.propTypes = {
  locales: PropTypes.arrayOf(localePropTypes.locale),
};

export default Locales;
