import PropTypes from 'prop-types';

const variable = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  inSheet: PropTypes.bool,
  value: PropTypes.string,
});

const category = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
});

export default { variable, category };
