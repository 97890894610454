import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import variablePropType from './variablePropTypes';
import VariableForm from './VariableForm';
import { FormHeader } from '../../common/Form';
import { VariableUrls } from '../../common/urls';
import { addVariable } from './actions';
import { getSelectedAppId, getCurrentPathName } from '../../utils/routerHelper';
import { categories } from './Constant';

const AddNewVariable = ({ submit, variables }) => {
  const appId = getSelectedAppId();
  const pathname = getCurrentPathName();

  const history = useHistory();

  const handleSubmit = (values) => {
    submit(appId, values, () => history.push(VariableUrls.getAppVariablesUrl(pathname)));
  };

  const handleCancel = () => {
    history.push(VariableUrls.getAppVariablesUrl(pathname));
  };

  return (
    <React.Fragment>
      <FormHeader>Add variable</FormHeader>
      <VariableForm
        onSubmit={handleSubmit}
        appId={appId}
        onCancel={handleCancel}
        variables={variables}
        categories={categories}
        initialValues={{
          category: {
            name: 'SievoInSheet',
            value: 5,
          },
          name: '',
          description: '',
          value: '',
          availableInSheet: true,
        }}
      />
    </React.Fragment>
  );
};

AddNewVariable.propTypes = {
  variables: PropTypes.arrayOf(variablePropType.variable),
  submit: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  submit: (appId, values, onSuccess) => dispatch(addVariable(appId, values, onSuccess)),
});

export default connect(
  null,
  mapDispatchToProps,
)(AddNewVariable);
