import { Theme } from '@sievo/react-common-components';

const styles = {
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  control: provided => ({
    ...provided,
    borderRadius: '3px',
    borderColor: Theme.colors.lightGray,
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',

    '&:hover': {
      borderColor: Theme.colors.lightGray,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: (state.isSelected && Theme.colors.lightBlue) || (state.isFocused && Theme.colors.highlight),
    '&:hover': {
      backgroundColor: !state.isSelected && Theme.colors.highlight,
    },
  }),
};

const inputDropdownTheme = {
  theme: {
    dropdownStyle: styles,
    searchStyle: {},
    titleStyle: {},
  },
};

export default inputDropdownTheme;
