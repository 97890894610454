import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Table = styled(props => <ReactTable {...props} />)`
  margin-top: 10px;
  background: ${props => props.theme.sievoLib.colors.white};
  border-color: ${props => props.theme.sievoLib.colors.lightGray};

  .rt-thead {
    border-color: ${props => props.theme.sievoLib.colors.lightGray}

    .rt-tr:hover {
      background: ${props => props.theme.sievoLib.colors.white} !important;
    }

    .-sort-desc {
      box-shadow: none !important;

      .sort-icon {
        display: block;
        color: ${props => props.theme.sievoLib.colors.gray};
      }
    }

    .-sort-asc {
      box-shadow: none !important;

      .sort-icon {
        display: block;
        color: ${props => props.theme.sievoLib.colors.gray};
        transform: rotate(180deg);
      }
    }

    .rt-th {
      padding: 7px 10px !important;
      border-color: ${props => props.theme.sievoLib.colors.lightGray}
    }
  }

  .rt-tbody {
    .rt-tr-group {
      border-color: ${props => props.theme.sievoLib.colors.lightGray};
    }

    .rt-tr:hover {
      background: ${props => props.theme.sievoLib.colors.highlight};
    }

    .rt-td {
      white-space: normal;
      padding: 7px 10px;
      overflow-wrap: break-word;
      border-color: ${props => props.theme.sievoLib.colors.lightGray};
    }
  }
  .sort-icon {
    display: none;
    margin-left: 10px;
    font-size: 19px; 
  }
`;

export const CenterAlignedContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: ${props => props.centerAlign ? 'center' : 'space-between'};
  font-weight: 600;
`;

export const ColumnHeader = ({ text, centerAlign = false }) => (
  <StyledHeader centerAlign={centerAlign}>
    {text}
    <i className="sievo-icon-expand_solid sort-icon" />
  </StyledHeader>
);

ColumnHeader.propTypes = {
  text: PropTypes.string,
  centerAlign: PropTypes.bool,
};

export const SubComponentContainer = styled.div`
  margin: 0.7rem;
  padding: 0.7rem;
  border: ${props => `1px ${props.theme.sievoLib.colors.lightGray} solid`};
`;

export const SubComponentItem = styled.div`
  margin-bottom: 1rem;
`;

export const CellWithButton = styled.div`
  display: flex;
  align-items: center;

  div {
    flex: 1;
    width: 0;
  }
`;
