/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Table, ColumnHeader, CenterAlignedContent } from '../../common/Table';
import taskPropTypes from './taskPropTypes';
import { formatDateString } from '../../utils/dateConverter';

const ExecutionDetailGrid = ({ executionDetails = [] }) => {
  const columns = React.useMemo(() => [
    {
      Header: '#',
      id: 'row',
      maxWidth: 50,
      filterable: false,
      Cell: row => <CenterAlignedContent>{row.index + 1}</CenterAlignedContent>,
    },
    {
      id: 'detailCreatedDate',
      Header: () => <ColumnHeader text="Execution Time" />,
      accessor: d => formatDateString(d.detailCreatedDate),
    },
    {
      Header: () => <ColumnHeader text="Task Step" />,
      accessor: 'message',
      minWidth: 200,
    },
    {
      Header: () => <ColumnHeader text="Step Id" />,
      accessor: 'id',
    },
  ], []);

  return executionDetails.length > 0
    && (
      <Table
        data={executionDetails}
        columns={columns}
        showPagination={false}
        pageSize={executionDetails.length}
        resizable
      />
    );
};

ExecutionDetailGrid.propTypes = {
  executionDetails: PropTypes.arrayOf(taskPropTypes.executionDetail),
};

export default ExecutionDetailGrid;
