export const APP_VARIABLES_LOADED = 'APP_VARIABLES_LOADED';
export const VARIABLE_NAMES_LOADED = 'VARIABLE_NAMES_LOADED';
export const VARIABLE_SELECTED = 'VARIABLE_SELECTED';
export const APP_SELECTED = 'APP_SELECTED';
export const VARIABLE_UPDATE_STARTED = 'VARIABLE_UPDATE_STARTED';
export const VARIABLE_UPDATE_COMPLETED = 'VARIABLE_UPDATE_COMPLETED';
export const TRIGGER_RELOAD_APPS_STARTED = 'TRIGGER_RELOAD_APPS_STARTED';
export const TRIGGER_RELOAD_APPS_COMPLETED = 'TRIGGER_RELOAD_APPS_COMPLETED';
const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case APP_VARIABLES_LOADED:
      return {
        ...state,
        appVariables: { ...state.appVariables, [action.data.variableIndex]: action.data.appVariables },
      };
    case VARIABLE_NAMES_LOADED:
      return { ...state, variables: { ...action.data.variableNames } };
    case VARIABLE_SELECTED:
      return { ...state, selectedVariable: { ...action.data.variable } };
    case VARIABLE_UPDATE_STARTED:
      return { ...state, isUpdating: true };
    case VARIABLE_UPDATE_COMPLETED:
      return updateAppVariables(state, action.data.appsToUpdate, action.data.newValue);
    case APP_SELECTED:
      return { ...state, selectedApps: [...action.data.selectedApps] };
    case TRIGGER_RELOAD_APPS_STARTED:
      return { ...state, triggerReloadInProgess: true };
    case TRIGGER_RELOAD_APPS_COMPLETED:
      return { ...state, triggerReloadInProgess: false };
    default:
      return state;
  }
};

const updateAppVariables = (state, appsToUpdate, newValue) => {
  const { appVariables, selectedVariable } = state;

  const updatedVariables = appVariables[selectedVariable.value].map((variable) => {
    if (appsToUpdate.includes(variable.appId)) {
      return { ...variable, value: newValue };
    }

    return variable;
  });

  return {
    ...state,
    appVariables: { ...state.appVariables, [selectedVariable.value]: updatedVariables },
    isUpdating: false,
  };
};

export default reducer;
