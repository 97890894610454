import ajaxService from '../../utils/ajaxService';

const variablesApiVersion = 'V1';

const variableService = {
  getVariables: appId => ajaxService.get(`${variablesApiVersion}/apps/${appId}/variables`),
  getVariablesByName: variableName => ajaxService.get(`${variablesApiVersion}/variables/byVariableName?name=${encodeURIComponent(variableName)}`),
  getDistinctVariableNames: () => ajaxService.get(`${variablesApiVersion}/variables/distinctNames`),
  delete: (appId, variableId) => ajaxService.delete(`${variablesApiVersion}/apps/${appId}/variables/${variableId}`),
  edit: async (appId, variableId, variable) => {
    const { appGuid, id, ...variableData } = variable;
    const edited = await ajaxService.put(`${variablesApiVersion}/apps/${appId}/variables/${variableId}`, variableData);
    return edited;
  },
  editMany: variables => ajaxService.put(`${variablesApiVersion}/variables/editMany`, variables),
  add: (appId, variable) => ajaxService.post(`${variablesApiVersion}/apps/${appId}/variables`, variable),
  copy: (appId, request) => ajaxService.post(`${variablesApiVersion}/tasks/${appId}/copy-variables`, request),
};

export default variableService;
