import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import commonPropTypes from './commonPropTypes';

const FieldError = styled.span`
  padding-left: 10px;
  color: red;
`;

const withValidation = (Children) => {
  const wrapped = (props) => {
    const { field, form } = props;
    const { errors, touched } = form;

    return (
      <div>
        <Children {...props} />
        {touched[field.name] && errors[field.name] && <FieldError>{errors[field.name]}</FieldError>}
      </div>
    );
  };

  wrapped.propTypes = {
    touched: PropTypes.bool,
    error: PropTypes.string,
    form: PropTypes.shape({ label: PropTypes.string }),
    field: PropTypes.shape({ name: PropTypes.string }),
    errors: PropTypes.arrayOf(commonPropTypes.error),
  };

  return wrapped;
};

withValidation.propTypes = {
  Children: PropTypes.element,
};

export default withValidation;
