import { EventType, PublicClientApplication } from '@azure/msal-browser';

const authority = process.env.REACT_APP_AUTHORITY;
const clientId = process.env.REACT_APP_CLIENT_ID;
const tokenAudience = process.env.REACT_APP_TOKEN_AUDIENCE;

const msalConfig = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${authority}`,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['profile', 'openid', 'email', 'offline_access', `${tokenAudience}/read`],
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise().then(tokenResponse => {
  if (tokenResponse !== null) {
    msalInstance.setActiveAccount(tokenResponse.account);
  }
}).catch(error => {
  console.error(error);
});

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});
