/* eslint-disable import/prefer-default-export */
import { isDate, format } from 'date-fns';

export const formatDateString = (dateString, dateFormat = 'yyyy-MM-dd HH:mm:ss') => {
  if (dateString === null || dateString === undefined || dateString.startsWith('0001')) return 'N/A';

  // quick hack to work around the issue that some datetime returned from server
  // ends with Z (meaning explicitly specify timezone) will be converted to local time
  // though all datetime values are in utc and we just want to display them in utc
  if (dateString.endsWith('Z')) {
    dateString = dateString.substring(0, dateString.length - 1);
  }

  const date = new Date(dateString);
  if (!isDate(date)) {
    throw new RangeError('dateString is not valid');
  }

  return `${format(date, dateFormat)} UTC`;
};
