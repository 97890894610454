import PropTypes from 'prop-types';

const message = PropTypes.shape({
  [PropTypes.string]: PropTypes.string,
});

const option = PropTypes.shape({
  title: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
});

const error = PropTypes.string;

export default { message, option, error };
