import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import { buttonThemes } from '@sievo/react-common-components';
import Button from '../../common/Buttons/Button';
import VariableGrid from './VariableGrid';
import Filters from './Filters';
import * as actions from './actions';
import variablePropTypes from './variablePropTypes';
import { VariableUrls } from '../../common/urls';

const DefaultTabView = (props) => {
  const { variables, deleteVariable, match } = props;
  const { url, params } = match;

  const [displayedVariables, setDisplayedVariables] = useState([]);

  return (
    <React.Fragment>
      <div className="clearfix">
        <div className="float-right">
          <Link to={VariableUrls.getAddUrl(url)}>
            <Button title="Add new variable" theme={buttonThemes.darkBlue}>
              Create Variable
            </Button>
          </Link>
        </div>
        <Filters setDisplayedVariables={setDisplayedVariables} variables={variables} />
      </div>
      <VariableGrid appId={params.appId} variables={displayedVariables} deleteVariable={deleteVariable} url={url} />
    </React.Fragment>
  );
};

DefaultTabView.propTypes = {
  variables: PropTypes.arrayOf(variablePropTypes.variable),
  match: ReactRouterPropTypes.match,
  deleteVariable: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  deleteVariable: (appId, variableId) => dispatch(actions.deleteVariable(appId, variableId)),
});

export default connect(
  null,
  mapDispatchToProps,
)(DefaultTabView);
