import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CustomerUrls } from './urls';
import TextLink from './TextLink';

const StyledNavigation = styled.ol`
  background: transparent;
  padding-left: 0;

  li:last-child a {
    font-weight: 600;
  }
`;

const Navigation = ({ appsLink, appDetailsLink }) => (
  <nav aria-label="breadcrumb">
    <StyledNavigation className="breadcrumb">
      <li className="breadcrumb-item">
        <TextLink to={CustomerUrls.home}>Customers</TextLink>
      </li>
      {appsLink ? (
        <li className="breadcrumb-item">
          <TextLink to={appsLink}>Apps</TextLink>
        </li>
      ) : null}
      {appDetailsLink ? (
        <li className="breadcrumb-item">
          <TextLink to={appDetailsLink}>App Details</TextLink>
        </li>
      ) : null}
    </StyledNavigation>
  </nav>
);

Navigation.propTypes = {
  appsLink: PropTypes.string,
  appDetailsLink: PropTypes.string,
};

export default Navigation;
