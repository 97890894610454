/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Autosuggest from 'react-autosuggest';
import withLabel from '../withLabel';
import withValidation from '../withValidation';

const FormAutosuggest = (props) => {
  const {
    options, onBlur = () => {}, field, form,
  } = props;

  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? options
      : options.filter(
        option => option && option.startsWith(inputValue),
      );
  };

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => <div>{suggestion}</div>;

  const onChange = (_event, { newValue }) => {
    form.setFieldValue(field.name, newValue);
    field.onChange(newValue);
  };

  const onSuggestionsFetchRequested = (value) => {
    setSuggestions(getSuggestions(value.value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (_event, { suggestionValue }) => {
    form.setFieldValue(field.name, suggestionValue);
    field.onChange(suggestionValue);
    onBlur(suggestionValue);
  };

  const customBlurHandler = () => {
    field.onChange(field.value);
    onBlur(field.value);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      customBlurHandler();
      e.target.blur();
    }
  };

  const inputProps = {
    value: field.value,
    onChange,
    onBlur: customBlurHandler,
    onKeyPress,
    className: 'form-control',
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      focusInputOnSuggestionClick={false}
      inputProps={inputProps}
      shouldRenderSuggestions={() => true}
    />
  );
};

FormAutosuggest.propTypes = {
  options: PropTypes.arrayOf(String).isRequired,
  onBlur: PropTypes.func,
  field: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({ setFieldValue: PropTypes.func }).isRequired,
};

const enhance = compose(
  withLabel,
  withValidation,
);

export default enhance(FormAutosuggest);
