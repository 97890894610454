import variableService from '../../AppDetails/Variables/variableService';
import taskService from '../../AppDetails/Tasks/taskService';
import * as ActionTypes from './reducer';
import withErrorHandling, { showSuccessMessage } from '../../common/withErrorHandling';

export const appVariablesLoaded = (variableIndex, appVariables) => (
  {
    type: ActionTypes.APP_VARIABLES_LOADED,
    data: { variableIndex, appVariables },
  });

export const variableNamesLoaded = variableNames => (
  {
    type: ActionTypes.VARIABLE_NAMES_LOADED,
    data: { variableNames },
  });

export const variableSelected = variable => ({
  type: ActionTypes.VARIABLE_SELECTED,
  data: { variable },
});

export const appsSelected = selectedApps => ({
  type: ActionTypes.APP_SELECTED,
  data: { selectedApps },
});

export const variableUpdateStarted = newValue => ({
  type: ActionTypes.VARIABLE_UPDATE_STARTED,
  data: { newValue },
});

export const variableUpdateCompleted = (appsToUpdate, newValue) => ({
  type: ActionTypes.VARIABLE_UPDATE_COMPLETED,
  data: { appsToUpdate, newValue },
});

export const getVariablesByName = (variableName, variableIndex) => async (dispatch) => {
  const appVariables = await variableService.getVariablesByName(variableName);

  dispatch(appVariablesLoaded(variableIndex, appVariables));
};

export const getDistinctVariableNames = () => withErrorHandling(async (dispatch) => {
  const variableNames = await variableService.getDistinctVariableNames();

  dispatch(variableNamesLoaded(variableNames));
});

export const updateSelectedVariables = newValue => async (dispatch, getState) => {
  dispatch(variableUpdateStarted());

  const { appVariables, selectedVariable, selectedApps } = getState().adminApp;

  const appsToUpdate = appVariables[selectedVariable.value]
    .filter((variable) => {
      const includedInSelectedApps = selectedApps.find(app => app.id === variable.appId) !== undefined;
      return includedInSelectedApps;
    })
    .map(v => v.appId);

  await variableService.editMany({
    appIds: appsToUpdate,
    variableName: selectedVariable.label,
    newValue,
  });

  dispatch(variableUpdateCompleted(appsToUpdate, newValue));
};

const triggerReloadAppsStarted = () => ({
  type: ActionTypes.TRIGGER_RELOAD_APPS_STARTED,
});

const triggerReloadAppsCompleted = () => ({
  type: ActionTypes.TRIGGER_RELOAD_APPS_COMPLETED,
});

export const reloadApps = () => withErrorHandling(async (dispatch, getState) => {
  dispatch(triggerReloadAppsStarted());
  const { selectedApps } = getState().adminApp;
  const selectedAppIds = selectedApps.map(app => app.id);
  await taskService.reloadApps(selectedAppIds);
  dispatch(triggerReloadAppsCompleted(selectedAppIds));
  dispatch(showSuccessMessage('Reload for selected apps triggered'));
});
