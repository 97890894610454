/* eslint-disable import/prefer-default-export */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  applyMiddleware, combineReducers, createStore, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { CookiesProvider } from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import { InteractionType } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { loginRequest, msalInstance } from './authConfig';
import App from './App';
import commonReducer from './reducer';
import customerReducer from './Customers/reducer';
import localeReducer from './Admin/Locales/reducer';
import appReducer from './Apps/reducer';
import variableReducer from './AppDetails/Variables/reducer';
import summaryReducer from './AppDetails/Summary/reducer';
import taskReducer from './AppDetails/Tasks/reducer';
import userReducer from './utils/Authorization/userReducer';
import adminAppReducer from './Admin/Apps/reducer';

const preloadedState = window.__PRELOADED_STATE__ || {};
delete window.__PRELOADED_STATE__;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  common: commonReducer,
  customer: customerReducer,
  locale: localeReducer,
  app: appReducer,
  variable: variableReducer,
  summary: summaryReducer,
  tasks: taskReducer,
  user: userReducer,
  adminApp: adminAppReducer,
  preloaded: () => preloadedState,
});

export const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}
    >
      <CookiesProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </CookiesProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>,
  document.getElementById('root'),
);
