import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest, msalInstance } from '../authConfig';

const processResponse = async (res, isJson = true) => {
  if (!res.ok) {
    throw res;
  }

  try {
    if (isJson) {
      return (await res.json()).data;
    }
    return await res.blob();

  } catch (error) {
    return [];
  }
};

const getBearerToken = async () => {
  const account = msalInstance.getActiveAccount();

  const request = {
    ...loginRequest,
    account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
      await msalInstance.acquireTokenRedirect(request);
    }
    throw error;
  }
};

const ajaxService = {
  get: async (url) => {
    const token = await getBearerToken();
    const res = await fetch(process.env.REACT_APP_API + url, {
      headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
      credentials: 'same-origin',
    });

    return processResponse(res);
  },
  getRaw: async (url) => {
    const token = await getBearerToken();
    const res = await fetch(process.env.REACT_APP_API + url, {
      headers: { Authorization: `Bearer ${token}` },
      credentials: 'same-origin',
    });

    return processResponse(res, false);
  },
  post: async (url, data) => {
    const token = await getBearerToken();
    const res = await fetch(process.env.REACT_APP_API + url, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return processResponse(res);
  },
  put: async (url, data) => {
    const token = await getBearerToken();
    const res = await fetch(process.env.REACT_APP_API + url, {
      method: 'put',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return processResponse(res);
  },
  delete: async (url, data) => {
    const token = await getBearerToken();
    const res = await fetch(process.env.REACT_APP_API + url, {
      method: 'delete',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return processResponse(res);
  },
};

export default ajaxService;
