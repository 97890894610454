import React, { useState, useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PageContent from '../common/PageContent';
import { StyledTabs } from '../common/Tabs';
import Locales from './Locales/Index';
import Apps from './Apps/Index';

const AdminView = (props) => {
  const [activeKey, setActiveKey] = useState('');
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (user && user.isAdmin) {
      setActiveKey('apps');
    } else {
      setActiveKey('locales');
    }
  }, [user]);

  return (
    <PageContent>
      <StyledTabs
        id="admin-view-tabs"
        activeKey={activeKey}
        onSelect={key => setActiveKey(key)}
      >
        {user && user.isAdmin && (
          <Tab eventKey="apps" title="Apps">
            <Apps {...props} />
          </Tab>
        )}
        <Tab eventKey="locales" title="Locales">
          <Locales {...props} />
        </Tab>
      </StyledTabs>
    </PageContent>
  );
};

export default AdminView;
