import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { iconButtonThemes } from '@sievo/react-common-components';
import {
  Container, TextContainer, HeaderButton, HeaderLink, LinkContainer,
} from './Styles';

const Header = (props) => {
  const { history } = props;

  const inCustomers = history.location.pathname.includes('customers');
  const text = 'Analytics Manager';

  return (
    <Container>
      <TextContainer>
        <h3>{text}</h3>
        {!inCustomers && '| Admin'}
      </TextContainer>
      <LinkContainer>
        {inCustomers ? (
          <Link to="/admin">
            <HeaderButton
              className="header-button"
              icon="sievo-icon-settings"
              theme={iconButtonThemes.transparentGray}
              tooltip="Admin view"
            />
          </Link>
        ) : (
          <HeaderLink to="/customers">Back to customers</HeaderLink>
        )}
      </LinkContainer>
    </Container>
  );
};

Header.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(Header);
