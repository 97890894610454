import ajaxService from '../utils/ajaxService';

const customerApiVersion = 'V1';

const customerService = {
  add: async (customer) => {
    const added = await ajaxService.post(`${customerApiVersion}/customers`, customer);
    return added;
  },
  getAll: () => ajaxService.get(`${customerApiVersion}/customers`),
  delete: async (customerId) => {
    await ajaxService.delete(`${customerApiVersion}/customers/${customerId}`);
  },
  update: async (customer) => {
    const { id, ...customerData } = { ...customer };
    const updated = await ajaxService.put(`${customerApiVersion}/customers/${id}`, customerData);
    return updated;
  },
  getHubAddress: async (customerId) => ajaxService.get(`${customerApiVersion}/customers/${customerId}/hubaddress`),
};

export default customerService;
