export const LOCALES_RECEIVED = 'LOCALES_RECEIVED';
export const LOADING_LOCALE_ITEMS = 'LOADING_LOCALE_ITEMS';
export const LOCALE_ITEMS_LOADED = 'LOCALE_ITEMS_LOADED';
export const SELECT_LOCALE = 'SELECT_LOCALE';

const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case LOCALES_RECEIVED:
      return { ...state, locales: action.data };
    default:
      return state;
  }
};

export default reducer;
