import PropTypes from 'prop-types';

const summary = PropTypes.shape({
  name: PropTypes.string,
  owner: PropTypes.string,
  publishTime: PropTypes.string,
  published: PropTypes.bool,
  description: PropTypes.string,
  fileSize: PropTypes.string,
  lastReloadTime: PropTypes.string,
  pushToProductionTime: PropTypes.string,
  createdDate: PropTypes.string,
  modifiedDate: PropTypes.string,
  modifiedUserName: PropTypes.string,
  isProductionApp: PropTypes.bool,
  localeConversionStatus: PropTypes.string,
});

export default { summary };
