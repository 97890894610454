import * as actionTypes from './actionTypes';
import service from './taskService';
import withErrorHandling from '../../common/withErrorHandling';

const loadTasksStarted = () => ({
  type: actionTypes.LOAD_TASKS_STARTED,
});

const loadTasksCompleted = (appId, tasks) => ({
  type: actionTypes.LOAD_TASKS_COMPLETED,
  data: { appId, tasks },
});

const loadTasksFailed = () => ({
  type: actionTypes.LOAD_TASKS_FAILED,
});

export const appReloaded = appId => ({
  type: actionTypes.APP_RELOADED,
  data: appId,
});

const reloadingApp = appId => ({
  type: actionTypes.RELOADING_APP,
  data: appId,
});

export const reload = (appId, notificationEmail) => withErrorHandling(async (dispatch) => {
  dispatch(reloadingApp(appId));
  await service.reload(appId, notificationEmail);
});

export const pushToProduction = (appId, reloadBeforePush, notificationEmail) => withErrorHandling(async (_) => {
  await service.pushToProduction(appId, reloadBeforePush, notificationEmail);
});

export const getAppStatus = (appId) => withErrorHandling(async (dispatch) => {
  dispatch(loadTasksStarted());
  const statusInterval = () => setTimeout(async () => {
    try {
      const response = await service.getAppStatus(appId);
      dispatch(loadTasksCompleted(appId, response));
      if (response?.busy) {
        statusInterval();
        return;
      }
      dispatch(appReloaded(appId));
    } catch (e) {
      dispatch(loadTasksFailed());
      throw e;
    }
  }, 2000);

  statusInterval();
});
