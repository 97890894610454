import { useMsal } from '@azure/msal-react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from './userActions';
import getUser from './userService';

const useAuth = () => {
  let user = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const [authUser, setAuthUser] = useState(user);
  const { instance } = useMsal();

  useEffect(() => {
    if (!user) {
      user = getUser(instance.getActiveAccount());
      dispatch(setUser(user));
      setAuthUser(user);
    }
  }, []);

  return authUser;
};

export default useAuth;
