import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { LoadingSpinner, buttonThemes } from '@sievo/react-common-components';
import Button from '../../common/Buttons/Button';
import { HeadingWithButtons } from '../../common/Headings';
import { TabContent } from '../../common/Tabs';
import TaskGrid from './TaskGrid';
import { getAppStatus } from './actions';
import appPropTypes from '../../Apps/appPropTypes';
import { getSelectedAppId } from '../../utils/routerHelper';
import {
  TextWithBottomMargin,
  StatusContainer,
  StatusText,
  StatusIcon,
  ButtonGroup,
} from './styles';
import { taskConstants } from '../../utils/constants';
import PushToProductionModal from './PushToProductionModal';
import ReloadAppModal from './ReloadAppModal';
import DownloadReloadLogButton from './DownloadReloadLogButton';
import { showErrorMessage } from '../../common/withErrorHandling';

export const Index = (props) => {
  const {
    tasks = {},
    isLoading,
    reloadApp,
    reloadingApps = [],
    pushAppToProduction,
    customerApps = [],
    checkAppStatus,
    showErrorMessageNotification,
  } = props;

  const appId = getSelectedAppId();
  const app = customerApps.find(a => a.id === appId);
  const isProdApp = app && app.name && app.name.toLowerCase().endsWith('_prod');
  const appTasks = tasks[appId];
  const showPushToProductionStatus = appTasks ? appTasks.pushToProductionStatus !== 'Unknown' : false;
  const pushCompleted = appTasks && (appTasks.pushToProductionStatus === taskConstants.status.success
    || appTasks.pushToProductionStatus === taskConstants.status.failed);
  const pushingToProduction = showPushToProductionStatus && !pushCompleted;
  const isAppReloading = reloadingApps.includes(appId);
  const isAppBusy = appTasks && appTasks?.busy;

  const [pushToProductionModal, setPushToProductionModal] = useState(false);
  const [reloadModal, setReloadModal] = useState(false);
  const user = useSelector(state => state.user.user);

  const getButtonTooltip = (defaultTooltip) => {
    if (pushingToProduction) return 'App is pushing to production';
    if (isAppReloading) return 'App is reloading';
    return defaultTooltip;
  };

  useEffect(() => {
    if (!isAppBusy) checkAppStatus(appId);
  }, []);

  const renderReloadTask = () => {
    if (appTasks && appTasks.reloadStatus) return <TaskGrid {...props} reloadStatus={appTasks.reloadStatus} expanded={{ 0: true }} />;
    if (isAppReloading) {
      return (
        <LoadingSpinner size="small" text="Reload started..." />
      );
    }
    return 'No reload task';
  };

  const confirmPushToProduction = (reloadAppDuringPush, notifyUser) => {
    setPushToProductionModal(false);
    pushAppToProduction(appId, reloadAppDuringPush, notifyUser);
    checkAppStatus(appId);
  };

  const confirmReloadUserApp = (notifyUser) => {
    setReloadModal(false);
    reloadApp(appId, notifyUser);
    checkAppStatus(appId);
  };

  if (isLoading) {
    return (
      <TabContent>
        <LoadingSpinner />
      </TabContent>
    );
  }

  return (
    <TabContent>
      <HeadingWithButtons>
        <h5>Push to Production Task</h5>
        <Button
          theme={buttonThemes.darkBlue}
          onClick={() => setPushToProductionModal(true)}
          disabled={isAppBusy || isProdApp}
          title={getButtonTooltip(isProdApp
            ? 'Production apps cannot be pushed to production'
            : 'Push app to production')}
        >
          Push to Production
        </Button>
      </HeadingWithButtons>
      {showPushToProductionStatus
        ? (
          <StatusContainer>
            <div>
              {'Push to production status:\u00A0'}
              <StatusText status={appTasks.pushToProductionStatus}>
                {appTasks.pushToProductionStatus}
              </StatusText>
            </div>
            {pushingToProduction && <LoadingSpinner text="" size="small" />}
            {pushCompleted && <StatusIcon status={appTasks.pushToProductionStatus} />}
          </StatusContainer>
        )
        : <TextWithBottomMargin>No active push to production task</TextWithBottomMargin>
      }
      <HeadingWithButtons>
        <h5>Reload Task</h5>
        <ButtonGroup>
          <DownloadReloadLogButton
            appId={appId}
            disabled={isAppBusy || !appTasks?.reloadStatus}
            showErrorMessage={showErrorMessageNotification}
          />
          <Button
            theme={buttonThemes.darkBlue}
            onClick={() => setReloadModal(true)}
            disabled={isAppBusy}
            title={getButtonTooltip('Reload app')}
          >
            Reload App
          </Button>
        </ButtonGroup>
      </HeadingWithButtons>
      {renderReloadTask()}
      <PushToProductionModal
        showModal={pushToProductionModal}
        closeModal={() => setPushToProductionModal(false)}
        confirmPushToProduction={confirmPushToProduction}
        notificationEmail={user?.id}
        appId={appId}
      />
      <ReloadAppModal
        showModal={reloadModal}
        closeModal={() => setReloadModal(false)}
        confirmReload={confirmReloadUserApp}
        notificationEmail={user?.id}
        appId={appId}
      />
    </TabContent>
  );
};

Index.propTypes = {
  tasks: PropTypes.shape(Object),
  isLoading: PropTypes.bool,
  pushAppToProduction: PropTypes.func,
  reloadingApps: PropTypes.arrayOf(PropTypes.string),
  appReloaded: PropTypes.func,
  reloadApp: PropTypes.func,
  customerApps: PropTypes.arrayOf(appPropTypes.app),
  checkAppStatus: PropTypes.func,
  showErrorMessageNotification: PropTypes.func,
};

const mapStateToProps = state => ({
  isLoading: state.tasks.isLoading,
  customerApps: state.app.customerApps,
});

const mapDispatchToProps = dispatch => ({
  checkAppStatus: appId => dispatch(getAppStatus(appId)),
  showErrorMessageNotification: message => dispatch(showErrorMessage(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
