import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingSpinner } from '@sievo/react-common-components';
import { TabContent } from '../../common/Tabs';
import AppsGrid from './AppsGrid';
import AppFilters from './AppFilters';
import appPropTypes from '../../Apps/appPropTypes';
import EditModal from './EditModal';
import VariableDropdownInput from './VariableDropdown';
import AppReloadButton from './AppReloadButton';
import * as appActions from '../../Apps/actions';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
  
  > span {
    margin-right: 5px;
  }
`;

const Apps = (props) => {
  const { apps = [], loadingApps, getApps } = props;
  const [filteredApps, setFilteredApps] = useState(apps);

  useEffect(() => {
    if (apps.length === 0) {
      getApps();
    }
  }, []);

  return (
    <TabContent>
      {!loadingApps
        ? (
          <>
            <Container>
              <VariableDropdownInput {...props} />
              <EditModal />
              <AppReloadButton />
            </Container>
            <AppFilters {...props} setFilteredApps={setFilteredApps} />
            <AppsGrid
              apps={filteredApps}
            />
          </>
        ) : <LoadingSpinner />}
    </TabContent>
  );
};

Apps.propTypes = {
  apps: PropTypes.arrayOf(appPropTypes.app),
  getApps: PropTypes.func,
  loadingApps: PropTypes.bool,
};

const mapStateToProps = state => ({
  apps: state.app.apps,
});

const mapDispatchToProps = dispatch => ({
  getApps: () => dispatch(appActions.getApps()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Apps);
