import PropTypes from 'prop-types';

export default {
  app: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    customerId: PropTypes.string,
    solutionArea: PropTypes.string,
    description: PropTypes.string,
    localeId: PropTypes.string,
  }),
};
