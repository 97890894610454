import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  BrowserRouter, Route, Redirect, Switch,
} from 'react-router-dom';
import { ThemeProvider } from '@sievo/react-common-components';
import Customers from './Customers/Index';
import AddNewCustomer from './Customers/AddNew';
import EditCustomer from './Customers/Edit';
import Apps from './Apps/Index';
import AddNewApp from './Apps/AddNew';
import CopyVariables from './Apps/CopyVariables';
import EditApp from './Apps/Edit';
import AppDetails from './AppDetails/Index';
import AdminView from './Admin/Index';
import './styles/index.scss';
import { mapStateToProps, mapDispatchToProps } from './utils/mapper';
import { CustomerUrls, AdminUrls } from './common/urls';
import Header from './common/Header/Header';
import MessageBar from './MessageBar';
import useAuth from './utils/Authorization/useAuth';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  height: 100%;
  background: ${props => props.theme.sievoLib.colors.background};
`;

const SelectionContainer = styled.div`
  display: block;
  width: 100%;
`;

const App = (props) => {
  const {
    getCustomers, getLocales,
  } = props;

  useAuth();

  useEffect(() => {
    getCustomers();
    getLocales();
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <Content className="App">
          <Header />
          <MessageBar />
          <SelectionContainer>
            <Switch>
              <Route path="/customers/:customerId/apps/edit/:appId/" render={() => <EditApp {...props} />} />
              <Route path="/customers/:customerId/apps/add-new" render={() => <AddNewApp {...props} />} />
              <Route
                path="/customers/:customerId/apps/copy-variables"
                render={routeProps => <CopyVariables {...props} {...routeProps} />}
              />
              <Route
                path="/customers/:customerId/apps/:appId"
                render={routeProps => <AppDetails {...props} {...routeProps} />}
              />
              <Route
                path="/customers/:customerId/apps/"
                render={routeProps => <Apps {...props} {...routeProps} />}
              />
              <Route path={CustomerUrls.addNew} render={() => <AddNewCustomer {...props} />} />
              <Route
                path={`${CustomerUrls.editBase}:customerId`}
                render={routeProps => <EditCustomer {...props} {...routeProps} />}
              />
              <Route exact path={CustomerUrls.home} render={() => <Customers {...props} />} />
              <Route exact path={AdminUrls.home} render={() => <AdminView {...props} />} />
              <Redirect to={CustomerUrls.home} />
            </Switch>
          </SelectionContainer>
        </Content>
      </ThemeProvider>
    </BrowserRouter>
  );
};

App.propTypes = {
  getCustomers: PropTypes.func,
  getLocales: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
