import texts from './texts';

const ValidateUnique = (value, otherValues) => {
  if (otherValues.some(otherValue => otherValue === value)) {
    return false;
  }
  return true;
};

export const validateCustomer = (customer, otherCustomers) => {
  const errors = {};

  if (!ValidateUnique(customer.name.toLowerCase(), otherCustomers.map(c => c.name.toLowerCase()))) {
    errors.name = texts.VALUE_MUST_BE_UNIQUE;
  }

  return errors;
};

export const validateApp = (app, otherApps) => {
  const errors = {};

  if (!ValidateUnique(app.name.toLowerCase(), otherApps.map(a => a.name.toLowerCase()))) {
    errors.name = texts.VALUE_MUST_BE_UNIQUE;
  }
  if (!ValidateUnique(app.id, otherApps.map(a => a.id))) {
    errors.id = texts.VALUE_MUST_BE_UNIQUE;
  }

  return errors;
};

export const validateVariable = (variable, otherVariables) => {
  const errors = {};

  const uniqueName = ValidateUnique(variable.name.toLowerCase(), otherVariables.map(v => v.name.toLowerCase()));
  if (!uniqueName) {
    errors.name = texts.VALUE_MUST_BE_UNIQUE;
  }

  return errors;
};
