import { SHOW_MESSAGE, HIDE_MESSAGE } from '../reducer';

export const MESSAGE_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARNING: 'WARNING',
};

const MESSAGE_TIMEOUT = 10 * 1000;

const showMessage = ({ message, type }) => ({
  type: SHOW_MESSAGE,
  data: {
    message,
    type,
  },
});

const hideMessage = () => ({
  type: HIDE_MESSAGE,
});

const formErrorMessage = async (error) => {
  const defaultMessage = 'Something went wrong. Please try again.';
  try {
    return (await error.json()).detail || defaultMessage;
  } catch (err) {
    return defaultMessage;
  }
};

export const showMessageWithTimeout = ({ message, type = MESSAGE_TYPE.SUCCESS, timeout = MESSAGE_TIMEOUT }) => (dispatch) => {
  dispatch(showMessage({ message, type }));
  setTimeout(() => dispatch(hideMessage()), timeout);
};

export const showInfoMessage = message => showMessageWithTimeout({ message, type: MESSAGE_TYPE.INFO });

export const showSuccessMessage = message => showMessageWithTimeout({ message });

export const showErrorMessage = message => showMessageWithTimeout({ message, type: MESSAGE_TYPE.ERROR });

const withErrorHandling = fn => async (dispatch, getState) => {
  try {
    await fn(dispatch, getState);
  } catch (e) {
    const message = await formErrorMessage(e);

    if (dispatch) dispatch(showErrorMessage(message));
  }
};

export default withErrorHandling;
