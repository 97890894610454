import * as Yup from 'yup';
import texts from '../../utils/texts';
import { validateCustomer } from '../../utils/validation';
import { isObjectEmpty } from '../../utils/helper';

export default {
  handleSubmit: (values, formProps) => {
    const {
      setErrors,
      setSubmitting,
      props: { onSubmit, allCustomers, initialValues },
    } = formProps;

    const currentCustomerId = initialValues == null ? undefined : initialValues.id;
    const errors = validateCustomer(values, allCustomers.filter(c => c.id !== currentCustomerId));
    if (!isObjectEmpty(errors)) {
      setErrors(errors);
      setSubmitting(false);
      return;
    }

    setTimeout(() => formProps.setSubmitting(false), 2000);
    onSubmit(values, formProps);
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required(texts.ENTER_VALUE),
    id: Yup.string()
      .required(texts.ENTER_VALUE)
      .uuid(texts.ENTER_VALUE_CORRECT_FORMAT),
    centralNode: Yup.string().required(texts.ENTER_VALUE),
  }),
  // NOTE: initialValues should always be given, in order validation errors to be shown on submit
  mapPropsToValues: ({ initialValues }) => ({
    id: initialValues ? initialValues.id : undefined,
    name: initialValues ? initialValues.name : '',
    description: initialValues ? initialValues.description : '',
    centralNode: initialValues ? initialValues.centralNode : '',
    frontEndCentralNode: initialValues ? initialValues.frontEndCentralNode : '',
  }),
};
