import * as actionTypes from './actionTypes';
import { PUSH_APP_TO_PROD_STATUS_CHANGED } from '../../common/sockets/actionTypes';

const updatePushToProductionStatus = (tasks, appId, status) => {
  const appTasks = tasks[appId] || {};
  appTasks.pushToProductionStatus = status;

  return { ...tasks, [appId]: { ...appTasks } };
};

const reducer = (state = { tasks: {}, reloadingApps: [] }, action) => {
  switch (action.type) {
    case PUSH_APP_TO_PROD_STATUS_CHANGED:
      return {
        ...state,
        tasks: updatePushToProductionStatus(state.tasks, action.data.appId, action.data.status),
      };
    case actionTypes.LOAD_TASKS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOAD_TASKS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.LOAD_TASKS_COMPLETED:
      return {
        ...state,
        tasks: { ...state.tasks, [action.data.appId]: { ...action.data.tasks } },
        isLoading: false,
      };
    case actionTypes.RELOADING_APP:
      return {
        ...state,
        reloadingApps: [...state.reloadingApps, action.data],
      };
    case actionTypes.APP_RELOADED:
      return {
        ...state,
        reloadingApps: state.reloadingApps.filter(id => id !== action.data),
      };
    default:
      return state;
  }
};

export default reducer;
