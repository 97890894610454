import PropTypes from 'prop-types';

export default {
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    centralNode: PropTypes.string,
    frontEndCentralNode: PropTypes.string,
  }),
};
