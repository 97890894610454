import * as actionTypes from './actionTypes';
import service from './variableService';
import withErrorHandling from '../../common/withErrorHandling';

const loadVariablesStarted = () => ({
  type: actionTypes.LOAD_VARIABLES_STARTED,
});

const loadVariablesCompleted = (appId, variables) => ({
  type: actionTypes.LOAD_VARIABLES_COMPLETED,
  data: { appId, variables },
});

const loadVariablesFailed = () => ({
  type: actionTypes.LOAD_VARIABLES_FAILED,
});

const deleteVariableCompleted = (appId, variableId) => ({
  type: actionTypes.DELETE_VARIABLE_COMPLETED,
  data: { appId, variableId },
});

export const loadVariables = appId => withErrorHandling(async (dispatch) => {
  try {
    dispatch(loadVariablesStarted());
    const response = await service.getVariables(appId);
    dispatch(loadVariablesCompleted(appId, response));
  } catch (e) {
    dispatch(loadVariablesFailed());
    throw e;
  }
});

export const deleteVariable = (appId, variableId) => withErrorHandling(async (dispatch) => {
  await service.delete(appId, variableId);
  dispatch(deleteVariableCompleted(appId, variableId));
});

const addVariableCompleted = (appId, variable) => ({
  type: actionTypes.ADD_VARIABLE_COMPLETED,
  data: { appId, variable },
});

export const addVariable = (appId, variableDetails, onSuccess) => withErrorHandling(async (dispatch) => {
  const variable = await service.add(appId, variableDetails);
  dispatch(addVariableCompleted(appId, variable));
  onSuccess();
});

const editVariableCompleted = (appId, variable) => ({
  type: actionTypes.EDIT_VARIABLE_COMPLETED,
  data: { appId, variable },
});

export const editVariable = (appId, variable, onSuccess) => withErrorHandling(async (dispatch) => {
  const variableId = variable.id;
  const variableSaved = await service.edit(appId, variableId, variable);
  dispatch(editVariableCompleted(appId, variableSaved));
  onSuccess();
});

const copyVariablesCompleted = (destinationAppId, destinationVariables) => ({
  type: actionTypes.COPY_VARIABLES_COMPLETED,
  data: { destinationAppId, destinationVariables },
});

export const copyVariables = (values, onSuccess) => withErrorHandling(async (dispatch) => {
  await service.copy(values.sourceAppId, values);

  const { destinationAppId } = values;
  const destinationVariables = await service.getVariables(destinationAppId);
  dispatch(copyVariablesCompleted(destinationAppId, destinationVariables));
  onSuccess();
});
