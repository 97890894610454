import React from 'react';
import { buttonThemes, TextCheckbox } from '@sievo/react-common-components';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Form, FormContainer } from '../../common/Form';
import LabeledDropdownInput from '../../common/Inputs/LabeledDropdownInput';
import Button from '../../common/Buttons/Button';
import ButtonContainer from '../../common/ButtonContainer';
import appPropTypes from '../appPropTypes';

const AppForm = (props) => {
  const {
    handleSubmit, onCancel, isSubmitting, setFieldValue, sourceApps, destinationApps,
  } = props;

  return (
    <FormContainer>
      <Form className="copyVariablesFrom" onSubmit={handleSubmit}>
        <Field name="sourceApp" type="text">
          {({ field, form }) => {
            const sourceApp = field.value ? sourceApps.find(a => a.value === field.value.sourceAppId) : null;
            return (
              <LabeledDropdownInput
                value={sourceApp}
                onChange={(option) => {
                  setFieldValue(field.name, option);
                }}
                options={sourceApps}
                form={form}
                field={field}
                label="Source App"
              />
            );
          }}
        </Field>
        <Field name="destinationApp" type="text">
          {({ field, form }) => (
            <LabeledDropdownInput
              value={field.value ? field.value : null}
              onChange={(option) => {
                setFieldValue(field.name, option);
              }}
              options={destinationApps}
              form={form}
              field={field}
              label="Destination App"
            />
          )}
        </Field>
        <Field name="clearExistingVariables">
          {({ field }) => {
            const { name, value = false, onChange } = field;
            return (
              <TextCheckbox
                name={name}
                text="Remove the Destination App's existing variables in configDB before copying (QlikApp won't be updated)"
                value={value}
                checked={value}
                onChange={onChange}
              />
            );
          }}
        </Field>
        <ButtonContainer>
          <Button type="submit" value="Save" disabled={isSubmitting} theme={buttonThemes.lightBlue}>
            Save
          </Button>
          <Button type="button" value="Cancel" disabled={isSubmitting} theme={buttonThemes.white} onClick={onCancel}>
            Cancel
          </Button>
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
};

AppForm.propTypes = {
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
  initialValues: PropTypes.shape({}),
  sourceApps: PropTypes.arrayOf(appPropTypes.app),
  destinationApps: PropTypes.arrayOf(appPropTypes.app),
};

export default AppForm;
