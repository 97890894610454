import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { buttonThemes } from '@sievo/react-common-components';
import Button from '../../common/Buttons/Button';
import ButtonContainer from '../../common/ButtonContainer';
import { Form } from '../../common/Form';
import appPropTypes from '../../Apps/appPropTypes';
import { updateSelectedVariables } from './actions';

const BodyContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

const Bold = styled.span`
  font-weight: bold;
`;

export const EditModalComponent = (props) => {
  const {
    updateVariables, selectedVariable, selectedApps = [], isUpdating,
  } = props;
  const [variableValue, setVariableValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const appNames = useMemo(() => selectedApps.map(app => app.name).join(', '), [selectedApps]);

  const submit = () => {
    setIsModalOpen(false);
    updateVariables(variableValue);
  };

  const cancel = () => {
    setIsModalOpen(false);
  };

  const onChangeValue = e => setVariableValue(e.target.value);

  const getButtonTooltip = () => {
    if (!selectedVariable || selectedApps.length === 0) return 'Select app(s) and a variable to edit the value';
    if (isUpdating) return 'Updating variable values';
    return 'Edit selected variable value for selected apps';
  };

  const buttonDisabled = () => {
    const hasSelectedVariable = selectedVariable && selectedVariable.value > -1;
    return !hasSelectedVariable || selectedApps.length === 0 || isUpdating;
  };

  return (
    <>
      <Button
        disabled={buttonDisabled()}
        onClick={() => setIsModalOpen(!isModalOpen)}
        theme={buttonThemes.darkBlue}
        title={getButtonTooltip()}
      >
        Edit Variable
      </Button>
      <Modal show={isModalOpen} centered>
        <Modal.Header>
          <Modal.Title>Edit variable value</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BodyContainer>
            <Bold>Selected variable</Bold>
            <p>{selectedVariable?.label}</p>
            <Bold>Selected apps</Bold>
            <p>{appNames}</p>
            <Form onSubmit={submit}>
              <Bold>New value</Bold>
              <input type="text" onChange={e => onChangeValue(e)} />
            </Form>
          </BodyContainer>
        </Modal.Body>
        <Modal.Footer>
          <ButtonContainer>
            <Button onClick={submit} theme={buttonThemes.lightBlue}>
              Save
            </Button>
            <Button onClick={cancel} theme={buttonThemes.white}>
              Cancel
            </Button>
          </ButtonContainer>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditModalComponent.propTypes = {
  updateVariables: PropTypes.func,
  selectedApps: PropTypes.arrayOf(appPropTypes.app),
  selectedVariable: PropTypes.instanceOf(Object),
  isUpdating: PropTypes.bool,
};

const mapStateToProps = state => ({
  selectedVariable: state.adminApp.selectedVariable,
  selectedApps: state.adminApp.selectedApps,
  isUpdating: state.adminApp.isUpdating,
});

const mapDispatchToProps = dispatch => ({
  updateVariables: newValue => dispatch(updateSelectedVariables(newValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModalComponent);
