import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@sievo/react-common-components';
import { useHistory } from 'react-router-dom';
import { SmallPageContent } from '../common/PageContent';
import AppForm from './AppForm';
import appPropTypes from './appPropTypes';
import localePropTypes from '../Admin/Locales/localePropTypes';
import customerPropTypes from '../Customers/customerPropTypes';
import { FormHeader } from '../common/Form';
import { getSelectedApp, getSelectedCustomer } from '../utils/routerHelper';
import * as appActions from './actions';

const Edit = (props) => {
  const {
    customerApps, locales = [], customers, updateApp, getCustomerApps,
  } = props;

  const selectedApp = getSelectedApp(customerApps);
  const selectedCustomer = getSelectedCustomer(customers);
  const history = useHistory();

  useEffect(() => {
    if (!customerApps && selectedCustomer) {
      getCustomerApps(selectedCustomer.id);
    }
  }, [customers]);

  if (!customerApps || !customers) return <LoadingSpinner />;

  const locale = locales.find(l => l.id === selectedApp.localeId);
  const initialLocale = locale && { value: locale.id.toString(), label: locale.name };

  const handleSubmit = values => updateApp(values, () => history.goBack());
  const handleCancel = () => history.goBack();

  return (
    <SmallPageContent>
      <FormHeader>Edit App</FormHeader>
      <AppForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialValues={{ ...selectedApp, localeId: initialLocale }}
        selectedCustomer={selectedCustomer}
        isProductionApp={selectedApp?.isProductionApp}
        {...props}
      />
    </SmallPageContent>
  );
};

Edit.propTypes = {
  customerApps: PropTypes.arrayOf(appPropTypes.app),
  updateApp: PropTypes.func,
  locales: PropTypes.arrayOf(localePropTypes.locale),
  customers: PropTypes.arrayOf(customerPropTypes.customer),
  getCustomerApps: PropTypes.func,
};

const mapStateToProps = state => ({
  customerApps: state.app.customerApps,
});

const mapDispatchToProps = dispatch => ({
  getCustomerApps: customerId => dispatch(appActions.getCustomerApps(customerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
