import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { buttonThemes } from '@sievo/react-common-components';
import Button from './Buttons/Button';
import ButtonContainer from './ButtonContainer';
import commonPropTypes from './commonPropTypes';

const ConfirmationDialog = ({
  show, proceed, dismiss, cancel, confirmation, options,
}) => (
  <Modal show={show} onHide={dismiss} className="confirmation-dialog">
    <Modal.Header closeButton>
      <Modal.Title>{options.title || 'Confirmation'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{confirmation}</Modal.Body>
    <Modal.Footer>
      <ButtonContainer>
        <Button onClick={proceed} theme={buttonThemes.lightBlue}>
          {options.confirmText || 'Delete'}
        </Button>
        <Button onClick={cancel} theme={buttonThemes.white}>
          {options.cancelText || 'Cancel'}
        </Button>
      </ButtonContainer>
    </Modal.Footer>
  </Modal>
);

ConfirmationDialog.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  confirmation: PropTypes.string,
  options: commonPropTypes.option,
};

const confirm = createConfirmation(confirmable(ConfirmationDialog));

// This is optional. But I recommend to define your confirm function easy to call.
export default function showConfirmation(confirmation, options = {}) {
  // You can pass whatever you want to the component. These arguments will be your Component's props
  return confirm({ confirmation, options });
}
