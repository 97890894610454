import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import styled from 'styled-components';
import { LoadingSpinner } from '@sievo/react-common-components';
import { Table, ColumnHeader } from '../../common/Table';
import localeService from './localeService';
import withErrorHandling from '../../common/withErrorHandling';

const Container = styled.div`
  margin-left: 30px;
  flex: 2;
`;

const LocaleItemsGrid = ({ selectedLocaleId }) => {
  const [localeItems, setLocaleItems] = useState([]);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getItemsAsync = withErrorHandling(async () => {
      if (!selectedLocaleId) {
        // Probably just opened the page, so nothing yet selected
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const items = await localeService.getLocaleItems(selectedLocaleId);
        setLocaleItems(items);
        setFailed(false);
      } catch {
        setLocaleItems([]);
        setFailed(true);
      } finally {
        setLoading(false);
      }
    });

    getItemsAsync();

  }, [selectedLocaleId]);

  const columns = [
    {
      Header: () => <ColumnHeader text="Name" />,
      accessor: 'name',
    },
    {
      Header: () => <ColumnHeader text="Value" />,
      accessor: 'value',
    },
    {
      accessor: 'id',
      show: false,
    },
  ];

  if (loading) {
    return (
      <Container>
        <h4>Locale Items</h4>
        <LoadingSpinner />
      </Container>
    );
  }

  if (failed) {
    return (
      <Container>
        <h4>Locale Items</h4>
        <p>Error while loading locale items!</p>
      </Container>
    );
  }

  return (
    <Container>
      <h4>Locale Items</h4>
      {localeItems.length > 0
        ? (
          <Table
            data={localeItems}
            columns={columns}
            pageSize={localeItems.length}
            showPagination={false}
            resizable
          />
        ) : <p>{selectedLocaleId ? 'This locale has no items yet' : 'Please select a locale to view its items'}</p>}
    </Container>
  );
};

LocaleItemsGrid.propTypes = {
  selectedLocaleId: PropTypes.string,
};

export default LocaleItemsGrid;
