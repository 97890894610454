export const variableConstants = {
  consultant: 'consultant',
  admin: 'admin',
  all: 'all',
  consultantCategories: ['SievoInSheet', 'SievoInLoadScript', 'ProjectSpecific'],
};

export const taskConstants = {
  status: {
    failed: 'Failed',
    success: 'CompletedSuccessfully',
  },
};

export const generalConstants = {
  emptyTime: '0001-01-01T00:00:00',
};
