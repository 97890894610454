import customerService from './customerService';
import * as ActionTypes from './reducer';
import withErrorHandling from '../common/withErrorHandling';
import { customerAppsDeletedSuccessfully } from '../Apps/actions';

const customersReceived = data => ({
  type: ActionTypes.CUSTOMERS_RECEIVED,
  data,
});

const customerAddedSuccessfully = customer => ({
  type: ActionTypes.CUSTOMER_ADDED,
  data: { customer },
});
const customerUpdatedSuccessfully = customer => ({
  type: ActionTypes.CUSTOMER_UPDATED,
  data: { customer },
});

const customerDeletedSuccessfully = id => ({
  type: ActionTypes.CUSTOMER_DELETED,
  data: { id },
});

const customerHubAddressReceived = data => ({
  type: ActionTypes.CUSTOMER_HUB_ADDRESS_RECEIVED,
  data,
});

export const customerLoadingStarted = () => ({ type: ActionTypes.LOADING_CUSTOMERS });

export const customerHubAddressLoadingStarted = () => ({ type: ActionTypes.LOADING_CUSTOMER_HUB_ADDRESS });

export const deleteCustomerById = customerId => withErrorHandling(async (dispatch) => {
  dispatch(customerLoadingStarted());
  await customerService.delete(customerId);
  dispatch(customerDeletedSuccessfully(customerId));
  dispatch(customerAppsDeletedSuccessfully(customerId));
});

export const getCustomers = () => withErrorHandling(async (dispatch) => {
  dispatch(customerLoadingStarted());
  const customers = await customerService.getAll();
  dispatch(customersReceived(customers));
});

export const customerAdded = (customer, onAdded) => withErrorHandling(async (dispatch) => {
  dispatch(customerLoadingStarted());
  const addedCustomer = await customerService.add(customer);
  dispatch(customerAddedSuccessfully(addedCustomer));
  onAdded();
});

export const updateCustomer = (customer, onUpdated) => withErrorHandling(async (dispatch) => {
  dispatch(customerLoadingStarted());
  const updatedCustomer = await customerService.update(customer);
  dispatch(customerUpdatedSuccessfully(updatedCustomer));
  onUpdated();
});

export const getCustomerHubAddress = (customerId) => withErrorHandling(async (dispatch) => {
  dispatch(customerHubAddressLoadingStarted());
  const hubAddress = await customerService.getHubAddress(customerId);
  dispatch(customerHubAddressReceived(hubAddress));
});
