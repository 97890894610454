const getUser = (account) => {
  const { name, username, idTokenClaims } = account;
  const isAdmin = isAdminUser(idTokenClaims);

  return { id: username, name, isAdmin };
};

const isAdminUser = (claims) => {
  const adminGroup = claims.groups?.find(group => group === process.env.REACT_APP_ADMIN_USER_GROUP_ID);
  return adminGroup != null;
};

export default getUser;
