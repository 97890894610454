/* eslint-disable react/destructuring-assignment */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from '@sievo/react-common-components';
import { Alert } from 'react-bootstrap';
import {
  ColumnHeader, Table, CenterAlignedContent,
} from '../../common/Table';
import LinkButton from '../../common/Buttons/LinkButton';
import { TaskDetailContainer, StatusText } from './styles';
import TableIcon from '../../common/TableIcon';
import ExecutionDetailGrid from './ExecutionDetailGrid';
import { formatDateString } from '../../utils/dateConverter';
import taskPropTypes from './taskPropTypes';
import FailureInstructionsModal from './FailureInstructionsModal';

const renderSubComponent = ({ row }) => {
  const { executionDetails } = row._original;
  const reloadingApp = row.status && row.status !== 'Success' && row.status !== 'Failed' && row.status !== 'Aborted';

  return (
    executionDetails && (
      <TaskDetailContainer>
        <ExecutionDetailGrid executionDetails={executionDetails} />
        {reloadingApp && (
        <CenterAlignedContent>
          <LoadingSpinner text="" size="small" />
        </CenterAlignedContent>
        )}
      </TaskDetailContainer>
    )
  );
};

renderSubComponent.propTypes = {
  row: PropTypes.shape({
    _original: PropTypes.shape({
      executionDetails: PropTypes.arrayOf(Object),
    }),
  }),
};

const TaskGrid = ({ reloadStatus, expanded }) => {
  const [isInstructionsModalVisible, setIsInstructionsModalVisible] = useState(false);

  const columns = useMemo(() => [
    {
      id: 'executionTime',
      Header: () => <ColumnHeader text="Execution Time" />,
      accessor: d => formatDateString(d.executionStopTime),
    },
    {
      Header: () => <ColumnHeader text="Description" />,
      accessor: 'description',
    },
    {
      Header: () => <ColumnHeader text="Status" />,
      accessor: 'status',
      maxWidth: 150,
      Cell: cellInfo => <StatusText status={cellInfo.original.status}>{cellInfo.original.status}</StatusText>,
    },
    {
      id: 'enabled',
      Header: () => <ColumnHeader text="Enabled" centerAlign />,
      accessor: 'enabled',
      maxWidth: 100,
      Cell: cellInfo => (
        <CenterAlignedContent>
          {cellInfo.original.enabled
            ? <TableIcon title="true" icon="check-mark" color="green" />
            : <TableIcon title="false" icon="close" color="red" />}
        </CenterAlignedContent>
      ),
    },
    {
      Header: () => <ColumnHeader text="Task Id" />,
      accessor: 'id',
    },
  ], []);

  return reloadStatus ? (
    <>
      {reloadStatus.status === 'Failed'
      && (
      <Alert variant="danger">
        Reload has failed. See
        <LinkButton type="button" variant="link" size="sm" onClick={() => setIsInstructionsModalVisible(true)}>Failed reload instructions</LinkButton>
        for help.
      </Alert>
      )}

      <Table
        data={[reloadStatus]}
        columns={columns}
        SubComponent={renderSubComponent}
        showPagination={false}
        resizable
        pageSize={1}
        expanded={expanded}
      />

      <FailureInstructionsModal showModal={isInstructionsModalVisible} closeModal={() => setIsInstructionsModalVisible(false)} />
    </>
  ) : (<div>No reload task</div>);
};

TaskGrid.propTypes = {
  reloadStatus: PropTypes.shape({
    executionTime: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    enabled: PropTypes.bool,
    id: PropTypes.string,
    executionDetails: PropTypes.arrayOf(taskPropTypes.executionDetail),
  }),
  expanded: PropTypes.objectOf(PropTypes.bool),
};

export default TaskGrid;
