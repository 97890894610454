import React from 'react';
import { Modal } from 'react-bootstrap';
import { buttonThemes } from '@sievo/react-common-components';
import PropTypes from 'prop-types';
import Button from '../../common/Buttons/Button';
import ButtonContainer from '../../common/ButtonContainer';

function FailureInstructionsModal({ showModal, closeModal }) {
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Failed Reload Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>Make sure there is no on-going data model refresh.</li>
          <li>
            Check logs by clicking
            {' '}
            <strong>Open latest reload log</strong>
            {' '}
            button and pay close attention to last couple lines which describe why the failure occurred.
          </li>
          <li>
            If you see the error
            {' '}
            <em>Qlik Sense connection wasn&apos;t established. Please make sure servers are up and running.</em>
            {' '}
            in the task description, Try again after a few minutes and if it still doesn&apos;t work please contact
            {' '}
            <strong>#prod-analytics</strong>
            {' '}
            slack channel.
          </li>
          <li>
            <strong>#prod-analytics</strong>
            {' '}
            is dedicated help slack channel for Analytics and Qlik related questions. Before asking a question do a small search in this channel if someone already answered similar question.
          </li>
          <li>
            If there is a service outage, Analytics Core team will inform about it in the public announcement channels. Make sure there is no ongoing outage before asking a new question in
            {' '}
            <strong>#prod-analytics</strong>
            .
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <ButtonContainer>
          <Button
            type="button"
            onClick={closeModal}
            theme={buttonThemes.darkBlue}
          >
            Close
          </Button>
        </ButtonContainer>
      </Modal.Footer>
    </Modal>
  );
}

FailureInstructionsModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default FailureInstructionsModal;
