import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import withLabel from '../withLabel';
import withValidation from '../withValidation';

const FormTextArea = ({ field, ...props }) => (
  <div>
    <textarea className="form-control" type="text" {...field} {...props} />
  </div>
);

FormTextArea.propTypes = {
  form: PropTypes.shape({ label: PropTypes.string }),
  field: PropTypes.shape({ name: PropTypes.string }),
};

const enhance = compose(
  withLabel,
  withValidation,
);

export default enhance(FormTextArea);
