import styled from 'styled-components';
import { RoundedButton } from '@sievo/react-common-components';

const Button = styled(RoundedButton)`
  font-size: 14px !important;
  min-width: initial;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 0.5rem;
`;

export default Button;
