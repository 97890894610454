export const CustomerUrls = {
  home: '/customers',
  addNew: '/customers/add-new',
  editBase: '/customers/edit/',
  taskBase: '/customers/tasks/',
  getEditUrl: id => `/customers/edit/${id}`,
};

const appDetailsBaseUrl = (customerId, appId) => `/customers/${customerId}/apps/${appId}`;

export const AppUrls = {
  getAppsUrl: customerId => `/customers/${customerId}/apps`,
  getAppDetailsSummaryUrl: (customerId, appId) => `${appDetailsBaseUrl(customerId, appId)}?key=summary`,
  getAppDetailsTasksUrl: (customerId, appId) => `${appDetailsBaseUrl(customerId, appId)}?key=tasks`,
  getAddUrl: customerId => `/customers/${customerId}/apps/add-new`,
  getEditUrl: (customerId, appId) => `/customers/${customerId}/apps/edit/${appId}`,
  getCopyVariablesUrl: customerId => `/customers/${customerId}/apps/copy-variables`,
};

export const VariableUrls = {
  getAppVariablesUrl: appDetailsUrl => `${appDetailsUrl}?key=variables`,
  getAddUrl: appDetailsUrl => `${appDetailsUrl}?key=variables&action=add`,
  getEditUrl: (appDetailsUrl, variableId) => `${appDetailsUrl}?key=variables&action=edit&variable=${variableId}`,
};

export const AdminUrls = {
  home: '/admin',
};
