export const LOADING_APPS = 'LOADING_APPS';
export const APPS_LOADED = 'APPS_LOADED';
export const APP_ADDED = 'APP_ADDED';
export const APP_UPDATED = 'APP_UPDATED';
export const APP_DELETED = 'APP_DELETED';
export const LOAD_APPS_HAVING_VARIABLES_COMPLETED = 'LOAD_APPS_HAVING_VARIABLES_COMPLETED';
export const CUSTOMER_APP_DELETED = 'CUSTOMER_APP_DELETED';
export const CUSTOMER_APPS_DELETED = 'CUSTOMER_APPS_DELETED';
export const CUSTOMER_APPS_LOADED = 'CUSTOMER_APPS_LOADED';

const initialState = {
  loadingApps: false,
  apps: [],
  hasVariables: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOADING_APPS:
      return { ...state, loadingApps: true };
    case APPS_LOADED:
      return { ...state, loadingApps: false, apps: action.data };
    case APP_ADDED:
      return {
        ...state,
        apps: [...state.apps, action.data.app],
      };
    case APP_UPDATED:
      return {
        ...state,
        apps: updateApps(state.apps, action.data.app),
        customerApps: updateApps(state.customerApps, action.data.app),
      };
    case APP_DELETED:
      return {
        ...state,
        apps: state.apps.filter(a => a.id !== action.data.id),
      };
    case CUSTOMER_APP_DELETED:
      return {
        ...state,
        customerApps: state.customerApps.filter(a => a.id !== action.data.id),
      };
    case CUSTOMER_APPS_LOADED:
      return { ...state, loadingApps: false, customerApps: action.data };
    case CUSTOMER_APPS_DELETED:
      return {
        ...state,
        apps: state.apps.filter(a => a.customerId !== action.data.customerId),
      };
    case LOAD_APPS_HAVING_VARIABLES_COMPLETED:
      return {
        ...state,
        hasVariables: [...action.data.appIds],
      };
    default:
      return state;
  }
};

const updateApps = (apps, updatedApp) => apps.map(a => (a.id === updatedApp.id ? updateApp(a, updatedApp) : a));

const updateApp = (originalApp, updatedApp) => {
  const {
    name, solutionArea, localeId, description, preloadApp,
  } = updatedApp;

  return {
    ...originalApp, name, solutionArea, localeId, description, preloadApp,
  };
};

export default reducer;
