import ajaxService from '../../utils/ajaxService';

const tasksApiVersion = 'V1';

const taskService = {
  start: (appId, taskId) => ajaxService.post(`${tasksApiVersion}/tasks/${taskId}/start/app/${appId}`),
  reload: (appId, notificationEmail = '') => ajaxService.post(`${tasksApiVersion}/tasks/${appId}/reload?notificationEmail=${notificationEmail}`),
  getReloadLog: (appId) => ajaxService.getRaw(`${tasksApiVersion}/tasks/${appId}/reload-log`),
  pushToProduction: (appId, reloadBeforePush, notificationEmail = '') => ajaxService.post(`${tasksApiVersion}/tasks/${appId}/push-to-production`, { reloadBeforePush, notificationEmail }),
  reloadApps: appIds => ajaxService.post(`${tasksApiVersion}/tasks/reloadApps`, appIds),
  getAppStatus: (appId) => ajaxService.get(`${tasksApiVersion}/tasks/${appId}`),
  stop: (taskId, customerId) => ajaxService.post(`${tasksApiVersion}/tasks/${taskId}/stop?customerId=${customerId}`),
};

export default taskService;
