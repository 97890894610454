import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Navigation from '../common/Navigation';
import PageContent from '../common/PageContent';
import CustomerAppsGrid from './CustomerAppsGrid';

const Apps = (props) => {
  const { location } = props;
  return (
    <Fragment>
      <PageContent>
        <Navigation appsLink={location.pathname} />
        <CustomerAppsGrid {...props} />
      </PageContent>
    </Fragment>
  );
};

Apps.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Apps;
