import styled from 'styled-components';

export const HeadingWithButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
    font-weight: 600;
  }
`;

export const SubHeading = styled.h6`
  margin: 10px 0 0 0;
  font-weight: 600;
`;
