import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { buttonThemes, LoadingSpinner } from '@sievo/react-common-components';
import Button from '../../common/Buttons/Button';
import taskService from './taskService';

const DownloadReloadLogButton = (props) => {
  const { appId, disabled, showErrorMessage } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <Button
      theme={buttonThemes.darkBlue}
      disabled={disabled || isDownloading}
      onClick={async () => {
        setIsDownloading(true);
        try {
          const file = await taskService.getReloadLog(appId);
          const fileUrl = URL.createObjectURL(file);
          window.open(fileUrl);
        } catch (Error) {
          showErrorMessage('Failed opening latest reload log');
        } finally {
          setIsDownloading(false);
        }
      }}
    >
      {
        isDownloading
          ? <LoadingSpinner size="xsmall" isBW text="Downloading log..." />
          : 'Open latest reload log'
      }
    </Button>
  );
};

DownloadReloadLogButton.propTypes = {
  appId: PropTypes.string,
  disabled: PropTypes.bool,
  showErrorMessage: PropTypes.func,
};

export default DownloadReloadLogButton;
