import ajaxService from '../../utils/ajaxService';

const localeApiVersion = 'V1';

const localeService = {
  getAll: () => ajaxService.get(`${localeApiVersion}/locales`),
  getLocaleItems: async (localeId) => {
    const localeItems = await ajaxService.get(`${localeApiVersion}/locales/${localeId}/items`);
    return localeItems;
  },
};

export default localeService;
