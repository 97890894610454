import styled from 'styled-components';
import { Theme } from '@sievo/react-common-components';

export const Form = styled.form`
  width: 100%;

  input {
    border-radius: 3px;
    border: 1px solid ${Theme.colors.lightGray};
    width: 100%;
    height: 30px;

    &:focus {
      border: 1px solid ${Theme.colors.lightBlue};
      box-shadow: 0 0 3px ${Theme.colors.highlight};
      outline: none;
    }
  }
`;

export const FormHeader = styled.h4`
  margin: 0 auto 10px auto;
`;

export const FormContainer = styled.div`
  margin: auto;
  background: ${Theme.colors.white};
  padding: 30px;
  border: 1px solid ${Theme.colors.lightGray};
`;
