export const LOADING_CUSTOMERS = 'LOADING_CUSTOMERS';
export const CUSTOMERS_RECEIVED = 'CUSTOMERS_RECEIVED';
export const CUSTOMER_ADDED = 'CUSTOMER_ADDED';
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED';
export const CUSTOMER_DELETED = 'CUSTOMER_DELETED';
export const LOADING_CUSTOMER_HUB_ADDRESS = 'LOADING_CUSTOMER_HUB_ADDRESS';
export const CUSTOMER_HUB_ADDRESS_RECEIVED = 'CUSTOMER_HUB_ADDRESS_RECEIVED';

const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case LOADING_CUSTOMERS:
      return { ...state, loading: true };
    case CUSTOMERS_RECEIVED:
      return { ...state, loading: false, customers: action.data };
    case CUSTOMER_ADDED:
      return { ...state, loading: false, customers: [...state.customers, action.data.customer] };
    case CUSTOMER_UPDATED:
      return {
        ...state,
        loading: false,
        customers: state.customers.map(c => c.id === action.data.customer.id ? action.data.customer : c),
      };
    case CUSTOMER_DELETED:
      return { ...state, loading: false, customers: state.customers.filter(c => c.id !== action.data.id) };
    case LOADING_CUSTOMER_HUB_ADDRESS:
      return {
        ...state, hubAddressLoading: true,
      };
    case CUSTOMER_HUB_ADDRESS_RECEIVED:
      return {
        ...state, hubAddressLoading: false, hubAddress: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
