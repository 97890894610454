import * as Yup from 'yup';
import texts from '../../../utils/texts';
import { validateVariable } from '../../../utils/validation';
import { isObjectEmpty } from '../../../utils/helper';

export default {
  handleSubmit: (values, formProps) => {
    const {
      setErrors,
      setSubmitting,
      props: {
        onSubmit, initialValues, variables, appId, categories,
      },
    } = formProps;

    const finalValues = {
      ...values,
      category: categories.find(c => c.value === values.category.value).name,
      appGuid: appId,
    };

    const errors = validate({ initialValues, finalValues, variables });
    if (!isObjectEmpty(errors)) {
      setErrors(errors);
      setSubmitting(false);
      return;
    }

    onSubmit(finalValues);
  },
  validationSchema: Yup.object().shape({
    description: Yup.string().nullable().max(255, texts.MAX_LENGTH_EXCEEDED(255)),
    name: Yup.string()
      .max(180, texts.MAX_LENGTH_EXCEEDED(180))
      .required(texts.ENTER_VALUE),
    value: Yup.string().nullable(),
    category: Yup.object()
      .nullable()
      .required(texts.SELECT_VALUE),
    availableInSheet: Yup.bool(),
  }),
  mapPropsToValues: ({ initialValues, categories }) => ({
    description: initialValues ? initialValues.description : '',
    name: initialValues ? initialValues.name : '',
    value: initialValues ? initialValues.value : '',
    category: initialValues ? getCategory(initialValues.category, categories) : null,
    availableInSheet: initialValues ? initialValues.availableInSheet : false,
  }),
  enableReinitialize: true,
};

function validate({ initialValues, finalValues, variables }) {
  const currentVariableId = initialValues && initialValues.id;
  const errors = validateVariable(finalValues, variables.filter(a => a.id !== currentVariableId));

  return errors;
}

const getCategory = (category, categories) => {
  if (!category || !categories) return {};

  return categories.find(c => c.value === category.value);
};
