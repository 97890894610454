/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { iconButtonThemes } from '@sievo/react-common-components';
import confirm from '../../common/confirm';
import { Table, CenterAlignedContent, ColumnHeader } from '../../common/Table';
import TableIcon from '../../common/TableIcon';
import ActionButton from '../../common/Buttons/ActionButton';
import variablePropTypes from './variablePropTypes';
import { VariableUrls } from '../../common/urls';

const TextWithTopMargin = styled.p`
  margin-top: 10px;
`;
TextWithTopMargin.displayName = 'TextWithTopMargin';

const VariableGrid = ({
  appId, variables, deleteVariable, url,
}) => {
  const handleDeleteClick = async (e, variable) => {
    e.stopPropagation();
    const confirmed = await confirm(`Are you sure you want to delete variable ${variable.name}?`, {
      title: 'Deleting variable',
    });
    if (!confirmed) return;

    deleteVariable(appId, variable.id);
  };

  const columns = [
    {
      id: 'category',
      Header: () => <ColumnHeader text="Category" />,
      accessor: d => d.category,
    },
    {
      Header: () => <ColumnHeader text="Name" />,
      accessor: 'name',
    },
    {
      Header: () => <ColumnHeader text="Value" />,
      accessor: 'value',
    },
    {
      Header: () => <ColumnHeader text="Description" />,
      accessor: 'description',
    },
    {
      id: 'in-sheet',
      Header: () => <ColumnHeader text="In Sheet" centerAlign />,
      maxWidth: 110,
      accessor: d => d.availableInSheet,
      Cell: cellInfo => (
        <CenterAlignedContent>
          {cellInfo.original.availableInSheet
            ? <TableIcon title="true" icon="check-mark" color="green" />
            : <TableIcon title="false" icon="close" color="red" />}
        </CenterAlignedContent>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      maxWidth: 80,
      sortable: false,
      Cell: cellInfo => (
        <CenterAlignedContent>
          <Link to={VariableUrls.getEditUrl(url, cellInfo.original.id)}>
            <ActionButton icon="sievo-icon-edit" theme={iconButtonThemes.white} tooltip="Edit variable" />
          </Link>
          <ActionButton
            onClick={(e) => {
              handleDeleteClick(e, cellInfo.original);
            }}
            icon="sievo-icon-trash"
            theme={iconButtonThemes.red}
            tooltip="Delete variable"
          />
        </CenterAlignedContent>
      ),
    },
  ];

  return variables && variables.length > 0
    ? (
      <Table
        data={variables}
        columns={columns}
        showPagination={false}
        pageSize={variables.length}
        resizable
        defaultSorted={[
          {
            id: 'in-sheet',
            desc: true,
          },
        ]}
      />
    )
    : <TextWithTopMargin>No variables found</TextWithTopMargin>;
};

VariableGrid.propTypes = {
  appId: PropTypes.string,
  url: PropTypes.string,
  variables: PropTypes.arrayOf(variablePropTypes.variable),
  deleteVariable: PropTypes.func,
};

export default VariableGrid;
