import { Tabs } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  &.nav-tabs {
    border-bottom: none;
  }

  a {
    background: ${props => props.theme.sievoLib.colors.blue};
    color: ${props => props.theme.sievoLib.colors.white};
    padding: 0.3rem 0.5rem;
    margin-right: 0.1rem;

    &:hover {
      background-color: ${props => props.theme.sievoLib.colors.lightBlue};
      color: ${props => props.theme.sievoLib.colors.white};
    }
    &:focus {
      outline: none;
    }
  }

  a.active {
    &:hover {
      background-color: white !important;
    }
  }
`;

export const TabContent = styled.div`
  padding: 20px;
  min-height: 500px;
  background: ${props => props.theme.sievoLib.colors.white};
  border: 1px solid ${props => props.theme.sievoLib.colors.lightGray};
  border-radius: 0 4px 4px 4px;
`;
