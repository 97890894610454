/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { iconButtonThemes, buttonThemes, LoadingSpinner } from '@sievo/react-common-components';
import confirm from '../common/confirm';
import { Table, CenterAlignedContent, ColumnHeader } from '../common/Table';
import { AppUrls, CustomerUrls } from '../common/urls';
import * as appActions from './actions';
import * as customerActions from '../Customers/actions';
import customerPropTypes from '../Customers/customerPropTypes';
import Button from '../common/Buttons/Button';
import ActionButton from '../common/Buttons/ActionButton';
import { HeadingWithButtons, SubHeading } from '../common/Headings';
import TextLink from '../common/TextLink';
import { getSelectedCustomer } from '../utils/routerHelper';
import { taskConstants, generalConstants } from '../utils/constants';
import { formatDateString } from '../utils/dateConverter';
import PushToProductionModal from '../AppDetails/Tasks/PushToProductionModal';
import ReloadAppModal from '../AppDetails/Tasks/ReloadAppModal';
import appPropTypes from './appPropTypes';

const CustomerAppsGrid = ({
  customerApps,
  getCustomerApps,
  customers = [],
  tasks = {},
  loadingApps,
  pushAppToProduction,
  deleteAppById,
  reloadApp,
  reloadingApps = [],
  customerHubAddressLoading,
  hubAddress,
  getCustomerHubAddress,
}) => {

  const selectedCustomer = getSelectedCustomer(customers);
  const [selectedAppId, setSelectedAppId] = useState('');
  const [pushToProductionModal, setPushToProductionModal] = useState(false);
  const [reloadModal, setReloadModal] = useState(false);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (selectedCustomer) {
      getCustomerApps(selectedCustomer.id);
      getCustomerHubAddress(selectedCustomer.id);
    }
  }, [customers]);

  const history = useHistory();

  const isPushingToProduction = (appId) => {
    const appTasks = tasks[appId];
    if (!appTasks) return false;
    return !['Unknown', taskConstants.status.success, taskConstants.status.failed]
      .includes(appTasks.pushToProductionStatus);
  };

  const isReloading = appId => reloadingApps.includes(appId);

  const getActionButtonTooltip = (appId, defaultTooltip) => {
    if (isPushingToProduction(appId)) return 'App is pushing to production';
    if (isReloading(appId)) return 'App is reloading';
    return defaultTooltip;
  };

  const confirmPushToProduction = (reloadAppDuringPush, notifyUser) => {
    setPushToProductionModal(false);
    pushAppToProduction(selectedAppId, reloadAppDuringPush, notifyUser);
    history.push(AppUrls.getAppDetailsTasksUrl(selectedCustomer.id, selectedAppId));
  };

  const confirmReloadUserApp = (notifyUser) => {
    setReloadModal(false);
    reloadApp(selectedAppId, notifyUser);
    history.push(AppUrls.getAppDetailsTasksUrl(selectedCustomer.id, selectedAppId));
  };

  const handleDeleteClick = async (e, cellInfo) => {
    e.stopPropagation();
    const confirmed = await confirm(`Are you sure you want to delete ${cellInfo.original.name}?`, {
      title: `Deleting ${cellInfo.original.name}`,
    });
    if (!confirmed) return;
    deleteAppById(cellInfo.original.customerId, cellInfo.original.id);
  };

  const columns = [
    {
      Header: () => <ColumnHeader text="Name" />,
      accessor: 'name',
      Cell: cellInfo => (
        <TextLink
          to={AppUrls.getAppDetailsSummaryUrl(selectedCustomer.id, cellInfo.original.id)}
        >
          {cellInfo.original.name}
        </TextLink>
      ),
    },
    {
      Header: () => <ColumnHeader text="Id" />,
      accessor: 'id',
    },
    {
      Header: () => <ColumnHeader text="Last Reload" />,
      accessor: 'lastReloadTime',
      Cell: cellInfo => cellInfo.original.lastReloadTime !== generalConstants.emptyTime
        ? formatDateString(cellInfo.original.lastReloadTime)
        : '',
    },
    {
      Header: () => <ColumnHeader text="Pushed to Production" />,
      accessor: 'pushToProductionTime',
      Cell: cellInfo => !cellInfo.original.isProductionApp
        && cellInfo.original.pushToProductionTime !== generalConstants.emptyTime
        ? formatDateString(cellInfo.original.pushToProductionTime)
        : '',
    },
    {
      Header: () => <ColumnHeader text="Description" />,
      accessor: 'description',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      sortable: false,
      width: 150,
      Cell: cellInfo => (
        <CenterAlignedContent>
          <Link to={AppUrls.getEditUrl(selectedCustomer.id, cellInfo.original.id)}>
            <ActionButton
              disabled={isPushingToProduction(cellInfo.original.id) || isReloading(cellInfo.original.id)}
              icon="sievo-icon-edit"
              theme={iconButtonThemes.white}
              tooltip={getActionButtonTooltip(cellInfo.original.id, 'Edit app')}
            />
          </Link>
          <ActionButton
            disabled={isPushingToProduction(cellInfo.original.id) || isReloading(cellInfo.original.id)}
            onClick={() => {
              setSelectedAppId(cellInfo.original.id);
              setReloadModal(true);
            }}
            icon="sievo-icon-repeat"
            theme={iconButtonThemes.white}
            tooltip={getActionButtonTooltip(cellInfo.original.id, 'Reload app')}
          />
          <ActionButton
            disabled={cellInfo.original.isProductionApp
              || isPushingToProduction(cellInfo.original.id)
              || isReloading(cellInfo.original.id)}
            onClick={() => {
              setSelectedAppId(cellInfo.original.id);
              setPushToProductionModal(true);
            }}
            icon="sievo-icon-upload"
            theme={iconButtonThemes.white}
            tooltip={getActionButtonTooltip(cellInfo.original.id, cellInfo.original.isProductionApp
              ? 'Production apps cannot be pushed to production'
              : 'Push app to production')}
          />
          <ActionButton
            disabled={isPushingToProduction(cellInfo.original.id) || isReloading(cellInfo.original.id)}
            onClick={e => handleDeleteClick(e, cellInfo)}
            icon="sievo-icon-trash"
            theme={iconButtonThemes.red}
            tooltip={getActionButtonTooltip(cellInfo.original.id, 'Delete app')}
          />
        </CenterAlignedContent>
      ),
    },
  ];

  const renderAppsBySolutionArea = () => {
    const solutionAreas = [...new Set(customerApps.map(c => c.solutionArea))].sort();
    return solutionAreas.map((solutionArea) => {
      const areaApps = customerApps.filter(app => app.solutionArea === solutionArea);
      return areaApps && areaApps.length > 0 && (
        <div key={solutionArea}>
          <SubHeading>{solutionArea}</SubHeading>
          <Table
            data={areaApps}
            columns={columns}
            showPagination={false}
            pageSize={areaApps.length}
            resizable
            defaultSorted={[{ id: 'name', desc: false }]}
          />
        </div>
      );
    });
  };

  if (loadingApps) return <LoadingSpinner />;

  return selectedCustomer ? (
    <Fragment>
      <HeadingWithButtons>
        <h4>{`${selectedCustomer.name}'s Apps `}</h4>
        <div>
          {!customerHubAddressLoading && hubAddress && (
          <a href={hubAddress} target="_blank" rel="noreferrer">
            <Button title="Access hub" theme={buttonThemes.green}>
              Hub
            </Button>
          </a>
          )}
          <Link to={AppUrls.getAddUrl(selectedCustomer.id)}>
            <Button title="Add new app" theme={buttonThemes.darkBlue}>
              Create App
            </Button>
          </Link>
          <Link to={AppUrls.getCopyVariablesUrl(selectedCustomer.id)}>
            <Button title="Copy variables from an app to another" theme={buttonThemes.whiteWithGrayBorder}>
              Copy Variables
            </Button>
          </Link>
          <Link to={CustomerUrls.getEditUrl(selectedCustomer.id)}>
            <Button title="Edit customer settings" theme={buttonThemes.whiteWithGrayBorder}>
              Edit Customer
            </Button>
          </Link>
        </div>
      </HeadingWithButtons>
      <PushToProductionModal
        showModal={pushToProductionModal}
        closeModal={() => setPushToProductionModal(false)}
        confirmPushToProduction={confirmPushToProduction}
        notificationEmail={user?.id}
        appId={selectedAppId}
      />
      <ReloadAppModal
        showModal={reloadModal}
        closeModal={() => setReloadModal(false)}
        confirmReload={confirmReloadUserApp}
        notificationEmail={user?.id}
        appId={selectedAppId}
      />
      {customerApps && customerApps.length > 0
        ? renderAppsBySolutionArea()
        : <p>This customer does not have any apps yet</p>}
    </Fragment>
  ) : null;
};

CustomerAppsGrid.propTypes = {
  customerApps: PropTypes.arrayOf(appPropTypes.app),
  getCustomerApps: PropTypes.func,
  loadingApps: PropTypes.bool,
  pushAppToProduction: PropTypes.func,
  customers: PropTypes.arrayOf(customerPropTypes.customer),
  tasks: PropTypes.shape(Object),
  deleteAppById: PropTypes.func,
  reloadApp: PropTypes.func,
  reloadingApps: PropTypes.arrayOf(PropTypes.string),
  getCustomerHubAddress: PropTypes.func,
  customerHubAddressLoading: PropTypes.bool,
  hubAddress: PropTypes.string,
};

const mapStateToProps = state => ({
  customerApps: state.app.customerApps,
  customerHubAddressLoading: state.customer.hubAddressLoading,
  hubAddress: state.customer.hubAddress,
});

const mapDispatchToProps = dispatch => ({
  getCustomerApps: customerId => dispatch(appActions.getCustomerApps(customerId)),
  getCustomerHubAddress: customerId => dispatch(customerActions.getCustomerHubAddress(customerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerAppsGrid);
