import ajaxService from '../utils/ajaxService';

const appsApiVersion = 'V1';

const appService = {
  add: async (app) => {
    const { customerId, ...appData } = app;
    const added = await ajaxService.post(`${appsApiVersion}/customers/${customerId}/apps`, appData);
    return added;
  },
  update: async (app) => {
    const { id, customerId, ...appData } = app;
    const updated = await ajaxService.put(`${appsApiVersion}/customers/${customerId}/apps/${id}`, appData);
    return updated;
  },
  delete: async (customerId, appId) => {
    await ajaxService.delete(`${appsApiVersion}/customers/${customerId}/apps/${appId}`);
  },
  getAll: () => ajaxService.get(`${appsApiVersion}/apps`),
  getAppDetails: (customerId, appId) => ajaxService.get(`${appsApiVersion}/customers/${customerId}/apps/${appId}`),
  getCustomerApps: customerId => ajaxService.get(`${appsApiVersion}/customers/${customerId}/apps`),
};

export default appService;
