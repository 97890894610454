import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LoadingSpinner } from '@sievo/react-common-components';
import { useHistory } from 'react-router-dom';
import { AppUrls } from '../common/urls';
import CopyVariablesForm from './CopyVariablesForm';
import { SmallPageContent } from '../common/PageContent';
import customerPropTypes from '../Customers/customerPropTypes';
import appPropTypes from './appPropTypes';
import { FormHeader } from '../common/Form';
import { copyVariables } from '../AppDetails/Variables/actions';
import { getSelectedCustomer } from '../utils/routerHelper';
import * as appActions from './actions';

const CopyVariables = (props) => {
  const {
    submit, customers = [], apps = [], getApps,
  } = props;

  useEffect(() => {
    if (apps.length === 0) {
      getApps();
    }
  }, []);

  const selectedCustomer = getSelectedCustomer(customers);
  const history = useHistory();

  const destinationApps = useMemo(() => {
    if (!selectedCustomer) return [];

    return apps
      .filter(a => a.customerId === selectedCustomer.id)
      .map(a => ({ label: a.name, value: a.id }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [apps, selectedCustomer]);

  const sourceApps = useMemo(() => apps
    .map((a) => {
      const customer = customers.find(c => c.id === a.customerId);
      return { label: `${customer?.name} - ${a.name}`, value: a.id };
    })
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())), [apps, selectedCustomer]);

  const handleSubmit = useCallback(values => submit(values, () => history.push(AppUrls.getAppsUrl(selectedCustomer.id))), [selectedCustomer]);
  const handleCancel = useCallback(() => history.push(AppUrls.getAppsUrl(selectedCustomer.id)), [selectedCustomer]);

  if (!apps.length) return <LoadingSpinner />;
  if (!selectedCustomer) return null;

  return (
    <SmallPageContent>
      <FormHeader>{`Copy variable for ${selectedCustomer.name}`}</FormHeader>
      <CopyVariablesForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        {...props}
        destinationApps={destinationApps}
        sourceApps={sourceApps}
      />
    </SmallPageContent>
  );
};

CopyVariables.propTypes = {
  customers: PropTypes.arrayOf(customerPropTypes.customer),
  apps: PropTypes.arrayOf(appPropTypes.app),
  getApps: PropTypes.func,
  submit: PropTypes.func,
};

const mapStateToProps = state => ({
  sourceAppIds: state.app.hasVariables,
  apps: state.app.apps,
});

const mapDispatchToProps = dispatch => ({
  getApps: () => dispatch(appActions.getApps()),
  submit: (values, onSuccess) => dispatch(copyVariables(values, onSuccess)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CopyVariables);
