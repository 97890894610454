import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { SmallPageContent } from '../common/PageContent';
import CustomerForm from './CustomerForm';
import CustomerPropTypes from './customerPropTypes';
import { CustomerUrls } from '../common/urls';
import { FormHeader } from '../common/Form';

const AddNew = ({ addCustomer, customers }) => {
  const history = useHistory();
  const handleSubmit = values => addCustomer(values, () => history.push(CustomerUrls.home));
  const handleCancel = () => history.push(CustomerUrls.home);

  return (
    <SmallPageContent>
      <FormHeader>Add Customer</FormHeader>
      <CustomerForm onSubmit={handleSubmit} onCancel={handleCancel} allCustomers={customers} createNew />
    </SmallPageContent>
  );
};

AddNew.propTypes = {
  addCustomer: PropTypes.func,
  customers: PropTypes.arrayOf(CustomerPropTypes.customer),
};

export default AddNew;
