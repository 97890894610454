import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Theme } from '@sievo/react-common-components';
import { taskConstants } from '../../utils/constants';

const statusColors = {
  Failed: Theme.colors.red,
  CompletedSuccessfully: Theme.colors.green,
  Success: Theme.colors.green,
};

export const TextWithBottomMargin = styled.p`
  margin-bottom: 3em;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 3em 0;
  padding: 1.25rem;
  border: solid 1px ${props => props.theme.sievoLib.colors.lightGray};

  div {
    display: flex;
  }
`;

export const StatusText = styled.p`
  font-weight: bold;
  color: ${props => statusColors[props.status] || props.theme.sievoLib.colors.black};
  margin: 0;
`;

const BigIcon = styled.span`
  font-size: 30px;
  font-weight: bold !important;
  color: ${props => props.status === taskConstants.status.success
    ? props.theme.sievoLib.colors.green
    : props.theme.sievoLib.colors.red};
`;

export const StatusIcon = ({ status }) => (
  <BigIcon
    status={status}
    className={status === taskConstants.status.success ? 'sievo-icon-check-mark' : 'sievo-icon-close'}
  />
);

StatusIcon.propTypes = {
  status: PropTypes.string,
};

export const TaskDetailContainer = styled.div`
  margin: 0 0.7rem 1rem 0.7rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  padding-bottom: 0.7rem;
`;
