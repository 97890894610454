import styled from 'styled-components';

const PageContent = styled.div`
  margin: 30px 8%;
`;

export const SmallPageContent = styled.div`
  margin: auto;
  max-width: 700px;
  padding-top: 2rem;
`;

export default PageContent;
